import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import ChatWindow from './ChatWindow';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'antd';
import CHATBOTLOGO from '../../images/Chat.svg';

class Launcher extends Component {
	constructor() {
		super();
		this.state = {
			isOpen:
				_.includes(_.get(window, 'location.href'), 'mobile-va.dubaichamber.com') ||
				_.includes(_.get(window, 'location.href'), 'chatbotva.dubaichamber.com') ||
				_.includes(_.get(window, 'location.href'), 'mobile-va-dev.dubaichamber.com') ||
				_.includes(_.get(window, 'location.href'), 'chatbotva-dev.dubaichamber.com')
					? true
					: false,
		};

		// Expose the openChatbot function globally
		window.openChatbot = this.openChatbot.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.mute) {
			return;
		}
		const nextMessage = nextProps.messageList[nextProps.messageList.length - 1];
		const isIncoming = (nextMessage || {}).author === 'them';
		const isNew = nextProps.messageList.length > this.props.messageList.length;
		if (isIncoming && isNew) {
			this.playIncomingMessageSound();
		}
	}

	playIncomingMessageSound() {
		var audio = new Audio('https://chatbotva-dev.dubaichamber.com/images/notification.mp3');
		audio.play();
	}

	handleClick() {
		if (this.props.handleClick !== undefined) {
			this.props.handleClick();
		} else {
			this.setState({
				isOpen: !this.state.isOpen,
			});
		}
	}

	openChatbot() {
		this.setState({ isOpen: true });
	}

	render() {
		const isOpen = this.props.hasOwnProperty('isOpen') ? this.props.isOpen : this.state.isOpen;
		const classList = ['sc-launcher', isOpen ? 'opened' : ''];

		return (
			<div id='sc-launcher'>
				{!isOpen ? (
					<div className='sc-launcher-bottom' onClick={this.handleClick.bind(this)} dir='ltr'>
						<Row className='sc-launcher-bottom-row'>
							<Col span={12} className='logo-align-center'>
								<Col span={24}>
									<div className='chatBotTooltip'>
										<div className='imagesOfchatIcon'>
											<img
												alt='Dubai Chamber of Commerce Virtual Assistant'
												src={'https://chatbotva-dev.dubaichamber.com/images/ChatIcon.svg'}
											/>
											<img
												alt='Dubai Chamber of Commerce Virtual Assistant'
												src={'https://chatbotva-dev.dubaichamber.com/images/ChatText.svg'}
											/>
										</div>
										<span className='chatbotTooltiptext'>
											Dubai Chamber of Commerce Virtual Assistant
										</span>
									</div>
								</Col>
							</Col>
						</Row>
					</div>
				) : (
					<ChatWindow
						downloadTanscript={this.props.downloadTanscript}
						onEndChat={this.props.onEndChat}
						messageList={this.props.messageList}
						onUserInputSubmit={this.props.onMessageWasSent}
						onFilesSelected={this.props.onFilesSelected}
						agentProfile={this.props.agentProfile}
						isOpen={isOpen}
						onClose={this.handleClick.bind(this)}
						arabicMode={this.props.arabicMode}
						showEmoji={this.props.showEmoji}
						modalVisible={this.props.modalVisible}
						modalContent={this.props.modalContent}
						onAudioInput={this.props.onAudioInput}
						handleClick={this.handleClick.bind(this)}
						textControl={this.props.textControl}
						tabs={this.props.tabs}
						showMobileTabs={this.props.showMobileTabs}
						loading={this.props.loading}
						sendMessage={this.props.sendMessage}
						chat={this.props.chat}
						onFileAttach={this.props.onFileAttach}
					/>
				)}
				<Helmet>
					<meta
						name='viewport'
						content='width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no'
					/>
				</Helmet>
			</div>
		);
	}
}

const MessageCount = (props) => {
	if (props.count === 0 || props.isOpen === true) {
		return null;
	}
	return <div className={'sc-new-messages-count'}>{props.count}</div>;
};

Launcher.propTypes = {
	onMessageWasReceived: PropTypes.func,
	onMessageWasSent: PropTypes.func,
	newMessagesCount: PropTypes.number,
	isOpen: PropTypes.bool,
	handleClick: PropTypes.func,
	messageList: PropTypes.arrayOf(PropTypes.object),
	mute: PropTypes.bool,
	showEmoji: PropTypes.bool,
};

Launcher.defaultProps = {
	newMessagesCount: 0,
	showEmoji: true,
};

export default Launcher;
