import React, { Component } from 'react';
import { Menu, Dropdown, Button, Tooltip, Modal } from 'antd';
import { PoweroffOutlined, MinusOutlined, InfoCircleFilled, DownloadOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';
import { Page, Text, View, Document, StyleSheet, usePDF, pdf } from '@react-pdf/renderer';
import Pdf from 'react-to-pdf';
import _ from 'lodash';

const ref = React.createRef();

class Header extends Component {
	state = { showDownload: false };
	menu = (
		<Menu>
			<Menu.Item>
				<a target='_blank' rel='noopener noreferrer' onClick={() => this.props.downloadTanscript()}>
					Download Transcription
				</a>
			</Menu.Item>
			<Menu.Item>
				<a target='_blank' rel='noopener noreferrer' onClick={() => this.props.onEndChat()}>
					End Chat
				</a>
			</Menu.Item>
		</Menu>
	);

	downloadTanscript = () => {
		console.log(this.props.chat);

		this.setState({ showDownload: true });
	};

	render() {
		const textchat = _.filter(this.props.chat, (e) => e.type === 'text');

		return (
			<div className='sc-header'>
				{isMobileOnly && (
					<InfoCircleFilled className='mobile-dots' onClick={() => this.props.showMobileTabs()} />
				)}
				<div className='sc-header--team-name'>
					<div className='header-title'>{this.props.teamName}</div>
				</div>
				<div className='sc-header--close-dots'>
					<Tooltip placement='top'>
						<Button
							shape='circle'
							size='small'
							className='header-button'
							onClick={() => this.props.onEndChat()}
							disabled={!this.props.textControl}
						>
							<PoweroffOutlined style={{ fontSize: '12px', color: 'white' }} />
						</Button>
					</Tooltip>
					{/* <Tooltip placement='top' title={'Download Transcript'}>
						<Button
							shape='circle'
							size='small'
							className='header-button'
							// onClick={() => this.props.downloadTanscript()}
							onClick={() => {
								this.downloadTanscript();
								// this.setState({ showDownload: true });
								// toPdf();
							}}
						>
							<DownloadOutlined style={{ fontSize: '12px', color: 'white' }} />
						</Button>
					</Tooltip> */}
					{this.state.showDownload && (
						<Modal
							title='Chat History'
							visible={this.state.showDownload}
							width={2000}
							style={{ height: '100vh' }}
							onCancel={() => this.setState({ showDownload: false })}
							footer={null}
						>
							<div
								ref={ref}
								style={{
									width: '100%',
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									padding: '1em',
									rowGap: '1em',
									overflow: 'scroll',
								}}
							>
								{_.map(textchat, (eachChat) => (
									<div
										style={{
											alignSelf: eachChat.author === 'them' ? 'start' : 'end',
											padding: '0.5em',
											background: eachChat.author === 'them' ? '#e3f5fc' : '#A4343a',
											color: eachChat.author === 'them' ? '#000' : '#fff',
										}}
									>
										{eachChat.data.text}
									</div>
								))}
							</div>
							<Pdf targetRef={ref} filename='chat-history.pdf'>
								{({ toPdf }) => (
									<Button
										shape='round'
										type='primary'
										size='small'
										// className='header-button'
										onClick={() => {
											toPdf();
											// this.setState({ showDownload: false });
										}}
									>
										Download
									</Button>
								)}
							</Pdf>
						</Modal>
					)}
					{!_.includes(_.get(window, 'location.href'), 'mobile-va.dubaichamber.com') && (
						<Button
							shape='circle'
							size='small'
							className='header-button'
							onClick={() => this.props.handleClick()}
						>
							<MinusOutlined style={{ fontSize: '12px', color: 'white' }} />
						</Button>
					)}
				</div>
			</div>
		);
	}
}

// const Wrapper = () => {
// 	const [instance, updateInstance] = usePDF({ document: MyDoc });

// 	const downoadPDF = () => {
// 		const blob = pdf(MyDoc).toBlob();

// 		console.log(blob);
// 		let tempLink = document.createElement('a');
// 		tempLink.href = instance.url;
// 		tempLink.setAttribute('download', 'test.pdf');
// 		tempLink.click();
// 	};

// 	return <Header downoadPDF={downoadPDF} />;
// };

export default Header;
