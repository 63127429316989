import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import axios from 'axios';
import development from '../../../config/development.json';
// import development from '../../../config/production.json';

const InternalForm = (props) => {
	const onFinish = async (values) => {
		//  authentication success: '/login_name"{{\"login_name\":\"TESTDEV66\"}}"'
		// authentication failure: /authentication_failed

		try {
			const response = await axios.post(development.auth, {
				username: values.username,
				password: values.password,
			});

			const json = { login_name: values.username };

			// const paylod = '/login_name' + `"{${JSON.stringify(json)}}"`

			const payload = '/login_name' + JSON.stringify(json);
			// console.log(paylod)

			// if (response.data.success) props.sendMessage(paylod, 'Please wait...We are verifying your credentials')
			if (response.data.success)
				props.sendMessage(
					payload,
					props.arabicMode
						? 'الرجاء الانتظار ... جاري التحقق من بيانات الاعتماد الخاصة بك'
						: 'Please wait...We are verifying your credentials',
				);
			else
				props.sendMessage(
					'/authentication_failed',
					props.arabicMode
						? 'الرجاء الانتظار ... جاري التحقق من بيانات الاعتماد الخاصة بك'
						: 'Please wait...We are verifying your credentials',
				);
		} catch (e) {
			props.sendMessage(
				'/authentication_failed',
				props.arabicMode
					? 'الرجاء الانتظار ... جاري التحقق من بيانات الاعتماد الخاصة بك'
					: 'Please wait...We are verifying your credentials',
			);
		}
	};

	const onCloseFormHandler = () => {
		props.onCloseBtnHandler();
	};

	return (
		<Card className='internal-form'>
			<div style={{ textAlign: 'end' }}>
				<Button onClick={onCloseFormHandler}>X</Button>
			</div>
			<Form
				name='basic'
				// labelCol={{ span: 8 }}
				// wrapperCol={{ span: 16 }}
				// initialValues={{ remember: true }}
				onFinish={onFinish}
				// onFinishFailed={onFinishFailed}
				// autoComplete="off"
			>
				<Form.Item
					label='Username'
					name='username'
					rules={[{ required: true, message: 'Please input your username!' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label='Password'
					name='password'
					rules={[{ required: true, message: 'Please input your password!' }]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item wrapperCol={{ span: 24 }}>
					<Button type='primary' htmlType='submit' style={{ width: '100%' }}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);
};

export default InternalForm;
