import React, { useState } from 'react'
import { List, Avatar, Card, Input } from 'antd';
import _ from 'lodash'

const { Search } = Input;

const ListTable = (props) => {

    const [fliteredList, setFilteredList] = useState(props.list)

    const onSearch = event => {
        const filteredList = props.list.filter((each) => _.includes(_.toLower(each), _.toLower(event.target.value)))
        setFilteredList(filteredList)
    };

    return (
        <Card style={{ width: '100%' }} size='small' extra={<Search placeholder="Search" onChange={onSearch} style={{ width: 200 }} />}>
            <List
                size='small'
                style={{ width: '100%' }}
                itemLayout="horizontal"
                dataSource={fliteredList}
                pagination={{
                    pageSize: 5,
                    size: 'small'
                }}
                renderItem={item => (
                    <List.Item >
                        <List.Item.Meta
                            avatar={<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{_.upperCase(_.first(item))}</Avatar>}
                            title={<a onClick={() => props.sendMessage(item, item)}>{item}</a>}
                        />
                    </List.Item>
                )}
            />
        </Card>
    )
}

export default ListTable