import React, { useState } from 'react';

import { Upload, Modal } from 'antd';
import axios from 'axios';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const UploadFile = (props) => {
	const [visible, setVisible] = useState(true);

	const draggerProps = {
		name: 'file',
		multiple: false,
		accept: '.pdf',
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer);
		},
		beforeUpload: async (file) => {
			console.log(file);

			if (file.type === 'application/pdf') {
				const formData = new FormData();
				formData.append('fileName', props.data[0].intent);
				formData.append('id', props.data[1].intent);
				formData.append('file', file);

				Modal.confirm({
					title: `Upload File - ${file.name}`,
					content: `Are you sure you want to upload ${file.name}?`,
					onOk: async () => {
						try {
							const res = await axios.post(
								'https://chatbotva.dubaichamber.com/api/dc-access/upload-doc',
								formData,
								{
									headers: {
										'Content-Type': 'multipart/form-data',
									},
								},
							);
							props.sendMessage(
								'/file_upload_success',
								props.arabicMode ? 'تم التحميل بنجاح' : 'Upload was Successful',
							);
						} catch (e) {
							props.sendMessage(
								'/file_upload_failed',
								props.arabicMode ? 'التحميل فشل' : 'Upload Failed',
							);
						}
						setVisible(false);
					},
				});
			} else {
				Modal.confirm({
					title: `Invalid File selected`,
					content: `The file selected is not with pdf extension.`,
					onOk: () => {
						Modal.destroyAll();
					},
				});
			}

			return false;
		},
	};

	return (
		<Dragger {...draggerProps} style={{ minWidth: '700px' }} status={'error'} disabled={!visible}>
			<p className='ant-upload-drag-icon'>
				<InboxOutlined style={{ fontSize: '5em' }} />
			</p>
			<p className='ant-upload-text'>Click or drag file to this area to upload</p>
			<p className='ant-upload-hint'>supports only pdf files</p>
		</Dragger>
	);
};

export default UploadFile;
