import React from 'react';
import { Image, Card } from 'antd';
import _ from 'lodash';

const { Meta } = Card;

const ImagePreview = (props) => {
	const { data } = props;
	return (
		<Card className='image-warpper' hoverable cover={<Image width='100%' src={_.get(data, [0, 'url'])} />}>
			<Meta title={_.get(data, [0, 'label'])} />
		</Card>
	);
};

export default ImagePreview;
