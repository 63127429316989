import React from 'react'
import { Collapse, List } from 'antd';
import _ from 'lodash';

const { Panel } = Collapse;

const data = [
    {
        title: 'Membership and Documentation Services package',
        submenu: [
            {
                title: 'Membership service',
                intent: ''
            },
            {
                title: 'Certificate of origin service',
                intent: ''
            },
            {
                title: 'Attestation service',
                intent: ''
            },
            {
                title: 'ATA carnet service',
                intent: ''
            },
        ]
    },
    {
        title: 'Business Development Service package',
        submenu: [
            {
                title: 'Business Research and information service',
                intent: ''
            },
            {
                title: 'Credit Rating service',
                intent: ''
            },
            {
                title: 'Legal Information service',
                intent: ""
            },
            {
                title: 'Mediation service',
                intent: ''
            },
        ]
    },
    {
        title: 'Sustainability Services Package',
        submenu: [
            {
                title: 'Dubai Chamber CSR Label service',
                intent: ''
            },
        ]
    },
    {
        title: 'Licensing Services Package',
        submenu: [
            {
                title: 'Business councils and Business groups service',
                intent: ''
            },
            {
                title: 'Government Liaison Office service',
                intent: ''
            },
            {
                title: 'Dubai Association Center service',
                intent: ''
            },
        ]
    },
    {
        title: 'Service provision channels',
        submenu: [
            {
                title: 'Service provision channels',
                intent: ''
            },
        ]
    }
];



const ServiceList = (props) => {
    console.log(props)

    const sendIntent = (intent, solutation) => {
        props.sendMessage(intent, solutation)
    }


    return (
        <Collapse size='small' accordion className='service-list' showArrow >
            {_.map(props.data, (each) => (
                <Panel header={each.title} key={each.title}>
                    <List
                        style={{ width: '100%', borderRadius: '5px' }}
                        dataSource={each.submenu}
                        renderItem={item => (
                            <List.Item onClick={() => sendIntent(item.intent, item.title)}>
                                {item.title}
                            </List.Item>
                        )}
                    />
                </Panel>

            ))}



        </Collapse>
    )
}

export default ServiceList