import React, { useState } from 'react';
import { Form, Row, Input, Card, Button } from 'antd';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import countries from '../constants/countries_ar.json';
import i18n from 'i18next';

import { initReactI18next, withTranslation } from 'react-i18next';
import { translationsAr, translationsEn } from '../Utils/constant';

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: { translation: translationsEn },
			ar: { translation: translationsAr },
		},
		lng: 'en',
		fallbackLng: 'en',
		interpolation: { escapeValue: false },
	});

const WelcomeForm = (props) => {
	const [form] = Form.useForm();
	const [formVisible, setFormVisibility] = useState(true);

	const onFinish = (values) => {
		form.validateFields().then(() => {
			setFormVisibility(false);
			props.connectToLiveAgentFn(values);
			// props.closeModal();
			// const formString = props.arabicMode ? ` معلوماتي هي [${name}]` : `My info is ${_.upperFirst(values.name)}`;
			// props.sendMessage({ data: { text: formString } }, values);
			// this.greet(formString, this.state.arabicMode ? 'تم التزويد بالمعلومات' : 'Information provided');
		});
	};

	return formVisible ? (
		<Row>
			<div
				style={{
					margin: 'auto',
					borderRadius: '16px',
					boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
					width: '90%',
				}}
			>
				<Card
					style={{ borderRadius: '15px', background: '#f1f1fb' }}
					justifyContent='center'
					direction='column'
				>
					<Form form={form} onFinish={onFinish} initialValues={{ phone: undefined }}>
						<Form.Item
							required={true}
							label={props.t('name')}
							name='name'
							rules={[
								{
									required: true,
									message: props.t('name_error'),
								},
							]}
						>
							<Input placeholder={props.t('name')} />
						</Form.Item>
						<Form.Item
							name='phone'
							label={props.t('phone')}
							required={true}
							rules={[
								{
									required: true,
									message: props.t('phone_error'),
								},
								() => ({
									validator: (value) => {
										if (form.isFieldTouched('phone')) {
											const allValues = form.getFieldsValue();
											let isValid = isValidPhoneNumber(allValues.phone);

											if (!isValid) {
												return Promise.reject(new Error(props.t('phone_error')));
											}
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<PhoneInput
								defaultCountry='AE'
								placeholder={props.t('phone')}
								labels={props.arabicMode ? countries : undefined}
								onChange={(value) => {
									const allValues = form.getFieldsValue();
									console.log('value', value);
									form.setFieldsValue({
										...allValues,
										phone: value,
									});
								}}
							/>
						</Form.Item>
						<Form.Item
							name='email'
							label={props.t('email')}
							required={true}
							rules={[
								{
									required: true,
									message: props.t('email_error'),
									type: 'email',
								},
							]}
						>
							<Input placeholder={props.t('email')} />
						</Form.Item>
						<Form.Item>
							<Button
								htmlType='submit'
								// shape='round'
								// size='large'
								// onClick={onFinish}
								style={{
									background: '#0a3b5c',
									float: 'right',
									border: '0px',
									color: '#ffff',
									width: '100%',
								}}
							>
								{props.t('submit')}
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</div>
			{/* <Col span={12}>
				<Form name='basic' form={form} onFinish={onFinish} initialValues={{ phone: undefined }}>
					<Row gutter={[12, 12]}>
						<Col span={24}>
							<Form.Item
								name='name'
								rules={[
									{
										required: true,
										message: props.t('name_error'),
									},
								]}
							>
								<Input className='chat-input' placeholder={props.t('name')} />
							</Form.Item>
						</Col>
						<Col span={24} className='phone-error'>
							<Form.Item
								name='phone'
								rules={[
									{
										required: false,
									},
									() => ({
										validator: (rule, value, callback) => {
											if (form.isFieldTouched('phone')) {
												const allValues = form.getFieldsValue();
												const isValid = isValidPhoneNumber(allValues.phone);
												if (!isValid) {
													return Promise.reject(new Error(props.t('phone_error')));
												}
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<PhoneInput
									defaultCountry='AE'
									placeholder={props.t('phone') + ' (Optional)'}
									labels={props.arabicMode ? countries : undefined}
									onChange={(value) => {
										const allValues = form.getFieldsValue();
										form.setFieldsValue({
											...allValues,
											phone: value,
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={24}>
							<Form.Item
								name='email'
								rules={[
									{
										message: props.t('email_error'),
										type: 'email',
									},
								]}
							>
								<Input placeholder={props.t('email') + ' (Optional)'} className='chat-input' />
							</Form.Item>
						</Col>
						<Col span={24} style={{ height: '40px' }}>
							<Form.Item>
								<Button
									htmlType='submit'
									shape='round'
									size='large'
									// onClick={onFinish}
									style={{
										background: '#0a3b5c',
										float: 'right',
										border: '0px',
										color: '#ffff',
										width: '100%',
									}}
								>
									{props.t('submit')}
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col> */}
		</Row>
	) : null;
};

export default withTranslation()(WelcomeForm);
