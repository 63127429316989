import React, { PureComponent } from 'react';
import { Button, Card, Collapse, Typography, Row, Col, Modal, Divider, Input, Tooltip, Form } from 'antd';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import Launcher from './components/Launcher';
import { LikeFilled, DislikeFilled, FileTextOutlined, UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { RealTimeAPI } from 'rocket.chat.realtime.api.rxjs';
import cryptoRandomString from 'crypto-random-string';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ListTable from './components/ListTable';

import i18n from 'i18next';
import { initReactI18next, withTranslation } from 'react-i18next';

import QueryForm from './components/NoAgentForm';
import Buttons from './components/Buttons';
import InternalForm from './components/InternalForm';
import ButtonsDisable from './components/ButtonsDisable';
import ImagePreview from './components/Image';
import ServiceList from './components/ServiceList';
import Slider from 'react-slick';
import emojione from 'emojione';
import { translationsEn, translationsAr } from './Utils/constant';
import WelcomeScreen from './components/Welcome';
import WelcomeFrom from './components/WelcomeForm';
import UploadFile from './components/UploadFile/UploadFile';
import TabsEnglishJSON from '../english_content.json';
import TabsArabicJSON from '../arabic_content.json';
import UserDetails from './components/UserDetailForm';

// import {
// 	chatbot_user,
// 	feedback,
// 	query,
// 	ENDPOINT_EN,
// 	ENDPOINT_AR,
// 	UrlLA,
// 	agentLoginurl,
// 	roomCloseUrl,
// 	// agentId,
// 	serverType,
// 	liveagent,
// 	liveagent_Dev,
// } from '../config/development.json';
import {
	chatbot_user,
	feedback,
	query,
	ENDPOINT_EN,
	ENDPOINT_AR,
	UrlLA,
	roomCloseUrl,
	// agentId,
	serverType,
	liveagent_Dev,
	liveagent,
} from '../config/production.json';

import { services } from './constants/countries_ar';
import 'rc-texty/assets/index.css';
import 'emoji-mart/css/emoji-mart.css';
import './App.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import 'react-phone-number-input/style.css';
import FeedbackSmily from './components/Feedback/feedbackSmily/feedbackSmily';
import { Config } from './config';
import TextArea from 'antd/lib/input/TextArea';

var isBH = false;

const { Panel } = Collapse;
const settings = {
	infinite: true,
	arrows: true,
	speed: 500,
	slidesToShow: 2,
	slidesToScroll: 1,
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: { translation: translationsEn },
			ar: { translation: translationsAr },
		},
		lng: 'en',
		fallbackLng: 'en',
		interpolation: { escapeValue: false },
	});

let token = '';
let rid = '';
let sid = '';

class App extends PureComponent {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();

		this.session_id =
			sessionStorage.getItem('session_id') || cryptoRandomString({ length: 17, type: 'alphanumeric' });
		sessionStorage.setItem('session_id', this.session_id);

		this.state = {
			name: 'User',
			email: '',
			phone: '',
			feedbackComment: '',
			chat: [],
			isLiveAgent: false,
			isLiveAgentConnected: false,
			lastUserActiveTs: false,
			isUserResponsing: null,
			question1Rating: '',
			feedbackUsername: '',
			allTimeouts: [],
			feedbackUserEmail: '',
			feedbackUserComment: '',
			question2Rating: '',
			question3Rating: '',
			isFeedBackIsLow: false,
			arabicMode: false,
			isFeedbackFormSubmitted: false,
			modalVisible: true,
			textControl: true,
			lastMsgLink: '',
			isFormDone: false,
			showForm: false,
			showMobileTab: false,
			tabs: [],
			loading: false,
			isBHOpen: false,
		};
		console.log('curent State is ', this.state);
	}

	// prod
	prodUrlLA = 'wss://liveagentva.dubaichamber.com/websocket';

	// dev
	devUrlLA = 'wss://liveagentva-dev.dubaichamber.com/websocket';

	realTimeAPI = null;

	noAgentAvailable = () => {
		this.setState({
			chat: [
				...this.state.chat,
				{
					author: 'them',
					type: 'other_message',
					data: {
						text: (
							<QueryForm
								query={query}
								name={this.state.name}
								mobile={this.state.phone}
								email={this.state.email}
								arabicMode={this.state.arabicMode}
								setChat={() => {
									this.setState({
										chat: [
											...this.state.chat,
											{
												author: 'them',
												type: 'text',
												data: {
													text: (
														<span>
															{this.state.arabicMode
																? 'شكرا لك على رسالتك وسنرد قريبا'
																: 'Thank you for your message, we will respond shortly.'}
														</span>
													),
												},
											},
										],
									});
								}}
							/>
						),
					},
				},
			],
			isLiveAgent: false,
		});
		this.greet('/restart');
	};

	downloadFile = async (url, details) => {
		// const res1 = await axios.post(agentLoginurl, {
		// 	user: Config.agentId,
		// 	password: Config.agentPwd,
		// });
		const res1 = await axios.post(liveagent, { userId: 'agentId', serverType });

		const headers = {
			'X-Auth-Token': _.get(res1, 'data.authToken'),
			'X-User-Id': _.get(res1, 'data.userId'),
			'Content-Type': 'multipart/form-data',
		};

		axios({
			url: `https://liveagentva.dubaichamber.com${url}?download`,
			// url: `https://liveagentva-dev.dubaichamber.com${url}?download`,
			method: 'GET',
			responseType: 'blob', // important
			headers,
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', details.title); //or any other extension
			document.body.appendChild(link);
			link.click();
		});
	};

	connectToLiveAgent = async (details) => {
		console.log('Connected to live Agent');
		this.setState({ textControl: false });
		try {
			this.setState({
				chat: [
					...this.state.chat,
					{
						author: 'them',
						type: 'other_message',
						data: {
							text: <Typography.Text>{this.props.t('agent_assign')}</Typography.Text>,
						},
					},
				],
			});

			this.realTimeAPI = new RealTimeAPI(UrlLA);

			sessionStorage.setItem('rid', rid);
			this.setState({ isUserResponsing: false });
			this.realTimeAPI.connectToServer();
			this.realTimeAPI.keepAlive().subscribe();
			const getUserInfo = await axios.post(liveagent, { userId: 'agentId', serverType });
			const headers = {
				'X-Auth-Token': _.get(getUserInfo, 'data.authToken'),
				'X-User-Id': _.get(getUserInfo, 'data.userId'),
				'Content-Type': 'multipart/form-data',
			};
			const getVisitorByMail = await axios({
				url: liveagent_Dev + 'v1/livechat/visitors.search?term=' + details.email,
				method: 'GET',
				headers,
			});
			const isVisitorPresent = _.get(getVisitorByMail, 'data.visitors', []);

			console.log('isVisitorPresent', isVisitorPresent);
			let isNewRoom = false;
			let roomInfoStatus = false;
			if (isVisitorPresent.length > 0) {
				// const getVisitorInfoById = await axios.get(
				// 	liveagent_Dev + 'v1/livechat/visitors.info?visitorId=' + isVisitorPresent[0]['_id'],
				// 	headers,
				// );
				const getVisitorInfoById = await axios({
					url: liveagent_Dev + 'v1/livechat/visitors.info?visitorId=' + isVisitorPresent[0]['_id'],
					method: 'GET',
					headers,
				});
				const visitorPresent = _.get(getVisitorInfoById, 'data.visitor', []);
				console.log('visitorPresent', visitorPresent);
				const tempRid = _.get(visitorPresent, 'lastChat._id', null);
				console.log('➡️   ~ App ~ connectToLiveAgent= ~ tempRid:', tempRid);
				token = _.get(visitorPresent, 'token', null);
				try {
					const checkRoom = await axios({
						url: liveagent_Dev + `v1/rooms.info?roomId=${tempRid}`,
						method: 'GET',
						headers,
					});

					console.log('checkRoom', checkRoom);
					roomInfoStatus = _.get(checkRoom, 'data.room.open', null);
					rid = tempRid;
				} catch (error) {
					console.log('error', error);

					isNewRoom = true;
					rid = this.session_id;
					console.log('➡️   ~ App ~ connectToLiveAgent= ~ rid:', rid);
				}

				// if (tempRid && tempRid.length == 20) {
				// 	rid = tempRid;
				// } else {
				// 	isNewRoom = true;
				// 	rid = this.session_id;
				// }
			} else {
				isNewRoom = true;
				token = cryptoRandomString({ length: 15, type: 'alphanumeric' });
				rid = this.session_id;
				console.log('➡️   ~ App ~ connectToLiveAgent= ~ rid:', rid);
				// rid = cryptoRandomString({ length: 8, type: 'alphanumeric' });
			}

			if (!isNewRoom) {
				// const checkRoom = await axios({
				// 	url: liveagent_Dev + `v1/rooms.info?roomId=${rid}`,
				// 	method: 'GET',
				// 	headers,
				// });

				// console.log('checkRoom', checkRoom);
				// const roomInfoStatus = _.get(checkRoom, 'data.room.open', null);
				// const roomInfoclosedBy = _.get(checkRoom, 'data.room.closedBy', null);
				console.log('roomInfoStatus', roomInfoStatus);
				if (!roomInfoStatus) {
					console.log('rid changes', rid);
					rid = this.session_id;
					// rid = cryptoRandomString({ length: 8, type: 'alphanumeric' });
				} else {
					this.setState({ isLiveAgentConnected: true });
					this.setState({ textControl: true });
					this.setState({
						chat: [
							...this.state.chat,
							{
								author: 'them',
								type: 'other_message',
								data: {
									text: <Divider>{this.props.t('live_agent_connect')}</Divider>,
								},
							},
						],
					});
				}
			}

			// window.clearTimeouts();

			// if (this.state.isUserResponsing !== 'ending') {
			// setTimeout(() => {
			// 	this.sendUserEndChatWarning(true);
			// }, 1000 * 30 * 1);

			// setTimeout(() => {
			// let time = new Date();
			// console.log('time: ', time.getTime());
			// time.setSeconds(time.getSeconds() + 30);
			// // this.setState({ lastUserActiveTs: time.getTime() });
			// console.log('time: ', time.getTime());
			// }, 1000 * 13 * 1);
			// }
			setTimeout(() => {
				this.sendAgentIsTakingTime(true);
			}, 1000 * 60 * 5);
			// }

			// if (roomInfoStatus) {
			// 	rid = cryptoRandomString({ length: 8, type: 'alphanumeric' });
			// }
			sid = cryptoRandomString({ length: 12, type: 'alphanumeric' });
			const mid = cryptoRandomString({ length: 10, type: 'alphanumeric' });
			//get initial data
			this.realTimeAPI.sendMessage({
				msg: 'method',
				method: 'livechat:getInitialData',
				params: [token],
				id: '1',
			});

			// const visiterresponse = await axios.post(liveagent_Dev + 'v1/livechat/visitor', {
			// 	visitor: {
			// 		name: details.name,
			// 		email: details.email,
			// 		department: 'DC_AGENT_EN',
			// 		token,
			// 		phone: details.phone,
			// 	},
			// });
			// console.log('visiterresponse', visiterresponse);
			console.log('rid', { rid, sid, token, mid });

			//register guest
			this.realTimeAPI.sendMessage({
				msg: 'method',
				method: 'livechat:registerGuest',
				params: [
					{
						token,
						name: details.name,
						phone: details.phone,
						email: details.email,
						department: this.state.arabicMode ? 'DC_AGENT_AR' : 'DC_AGENT_EN',
					},
				],
				id: '5',
			});

			//login

			// send message
			this.realTimeAPI.sendMessage({
				msg: 'method',
				method: 'sendMessageLivechat',
				params: [
					{
						_id: mid,
						rid,
						msg: `${details.name} wants to connect.`,
						token,
						token,
					},
				],
				id: '11',
			});

			this.realTimeAPI.sendMessage({
				msg: 'method',
				method: 'livechat:sendOfflineMessage',
				params: [{ name: details.name, email: details.email, message: 'Hello!' }],
				id: '3',
			});

			// const res1 = await axios.get(liveagent);
			const res1 = await axios.post(liveagent, { userId: 'agentId', serverType });
			this.realTimeAPI.loginWithAuthToken(_.get(res1, 'data.authToken', ''));
			// await this.getRoomInfo();
			this.realTimeAPI.sendMessage({
				msg: 'sub',
				id: sid,
				name: 'stream-room-messages',
				params: [rid, false],
			});

			this.realTimeAPI.sendMessage({
				msg: 'sub',
				id: '6NctZomXL3ZdtKNsn',
				name: 'stream-livechat-room',
				params: [
					rid,
					{
						useCollection: true,
						args: [
							{
								token,
							},
						],
					},
				],
			});
			// this.setState({ isUserResponsing: true });
			// if (this.state.isUserResponsing !== 'ending' && this.state.isLiveAgent == true) {
			// }

			this.realTimeAPI.subscribe((message) => {
				console.log('==== chatbot message ====', message);
				// Here
				if (_.get(message, 'error.error') === 'no-agent-online') {
					const startTime = moment('08:00 AM', 'LT');
					this.setState({ textControl: false });
					const endTime = moment('04:30 PM', 'LT');

					if (!moment().isBetween(startTime, endTime) || moment().weekday() === 5) {
						this.setState({
							chat: [
								...this.state.chat,
								{
									author: 'them',
									type: 'other_message',
									data: {
										text: (
											<Typography.Text>
												{this.props.t('live_agent_unavailable_hrs')}
											</Typography.Text>
										),
									},
								},
							],
						});
					} else {
						this.setState({
							chat: [
								...this.state.chat,
								{
									author: 'them',
									type: 'other_message',
									data: {
										text: (
											<Typography.Text>{this.props.t('live_agent_unavailable')}</Typography.Text>
										),
									},
								},
							],
						});
					}
					this.noAgentAvailable();
				} else if (
					_.get(message, 'id') === '11' &&
					_.get(message, 'result.newRoom') &&
					_.includes(_.get(message, 'result.msg'), 'wants to connect.')
				) {
					// this.setState({
					// 	chat: [
					// 		...this.state.chat,
					// 		{
					// 			author: 'them',
					// 			type: 'other_message',
					// 			data: {
					// 				text: <Divider>{this.props.t('live_agent_connect')}</Divider>,
					// 			},
					// 		},
					// 	],
					// });
				} else {
					if (message.collection === 'stream-room-messages') {
						const messageRecieved = _.get(message, ['fields', 'args', 0, 'msg']);
						if (messageRecieved === 'promptTranscript') {
							this.endChat(true);
						} else if (_.includes(messageRecieved, 'has disconnected')) {
						} else if (messageRecieved === 'connected') {
						} else {
							console.log(_.get(message, ['fields', 'args', 0, 'alias']));
							const output = emojione.toImage(messageRecieved);

							if (
								_.get(message, ['fields', 'args', 0, 'attachments']) &&
								_.get(message, ['fields', 'args', 0, 'u', 'username']) !== 'fileUpload'
							) {
								console.log('=== inside===', _.get(message, ['fields', 'args', 0]));
								console.log('mes', _.get(message, ['fields', 'args', 0, 'alias']) === this.state.name);
								console.log(
									'mes121',
									_.get(message, ['fields', 'args', 0, 'alias']) === this.state.name ? 'me' : 'them',
								);
								this.setState({
									chat: [
										...this.state.chat,
										{
											author:
												_.get(message, ['fields', 'args', 0, 'alias']) === this.state.name
													? 'me'
													: 'them',
											user:
												_.get(message, ['fields', 'args', 0, 'alias']) === this.state.name
													? this.state.name
													: _.get(message, ['fields', 'args', 0, 'u', 'username']),
											type: 'text',
											data: {
												text: (
													<div
														style={{
															display: 'flex',
															justifyContent: 'center',
															flexDirection: 'column',
															rowGap: '1em',
														}}
													>
														<div>
															<Typography.Text>
																{_.get(message, [
																	'fields',
																	'args',
																	0,
																	'attachments',
																	0,
																	'description',
																])}
															</Typography.Text>
														</div>
														<Card
															size='small'
															style={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																columnGap: '1em',
																minWidth: '100px',
															}}
														>
															<FileTextOutlined className='upload-file-icon' />
															<div>
																<Typography.Text>
																	{_.get(message, [
																		'fields',
																		'args',
																		0,
																		'file',
																		'name',
																	])}
																</Typography.Text>
															</div>
															<Button
																type='primary'
																onClick={() =>
																	this.downloadFile(
																		_.get(message, [
																			'fields',
																			'args',
																			0,
																			'attachments',
																			0,
																			'title_link',
																		]),
																		_.get(message, [
																			'fields',
																			'args',
																			0,
																			'attachments',
																			0,
																		]),
																	)
																}
															>
																Download
															</Button>
														</Card>
													</div>
												),
											},
										},
									],
								});
							} else {
								console.log('=== inside===', _.get(message, ['fields', 'args', 0]));
								const isAgent =
									_.get(message, ['fields', 'args', 0, 'alias']) === this.state.name ? 'me' : 'them';
								if (isAgent === 'them') {
									if (this.state.isLiveAgentConnected !== true) {
										this.setState({ isLiveAgentConnected: true });
										this.setState({ textControl: true });
										this.setState({
											chat: [
												...this.state.chat,
												{
													author: 'them',
													type: 'other_message',
													data: {
														text: <Divider>{this.props.t('live_agent_connect')}</Divider>,
													},
												},
											],
										});
									}
									let time = new Date();
									time.setSeconds(time.getMinutes() + 4);
									this.setState({ lastUserActiveTs: time.getTime() });
									this.setState({ isUserResponsing: false });
									setTimeout(() => {
										this.sendUserEndChatWarning();
									}, 1000 * 60 * 5); // For Production
								} else if (isAgent === 'me') {
									this.setState({ isUserResponsing: true });
								}
								// console.log(
								// 	'mes121',
								// 	_.get(message, ['fields', 'args', 0, 'alias']) === this.state.name ? 'me' : 'them',
								// );

								if (_.get(message, ['fields', 'args', 0, 'u', 'username']) !== 'fileUpload') {
									console.log('message:12 ', message);
									let messageSplit = output.split(' ');

									if (this.state.lastMsgLink === output) {
										return;
									} else {
										this.setState({ lastMsgLink: '' });
									}
									if (messageSplit.length > 0) {
										messageSplit.forEach((msg) => {
											if (msg.includes('https')) {
												this.setState({ lastMsgLink: output });
												return;
											}
										});
									}
									console.log('output', { output, lastMsgLink: this.state.lastMsgLink });
									this.setState({
										chat: [
											...this.state.chat,
											{
												author:
													_.get(message, ['fields', 'args', 0, 'alias']) === this.state.name
														? 'me'
														: 'them',
												user:
													_.get(message, ['fields', 'args', 0, 'alias']) === this.state.name
														? this.state.name
														: _.get(message, ['fields', 'args', 0, 'u', 'username']),
												type: 'text',
												data: {
													text: (
														<div
															className='text-message-recieve'
															dangerouslySetInnerHTML={{ __html: this.urlify(output) }}
														></div>
													),
												},
											},
										],
									});
								}
							}
						}
					}
				}
			});
		} catch (e) {
			console.log('error', e);
		}
	};

	downloadTanscript = () => {
		if (this.state.isUserResponsing !== true) this.setState({ isUserResponsing: true });
		console.log('activity', this.state.isUserResponsing);
		const printContents = document.getElementsByClassName('sc-message-list')[0].innerHTML;
		document.body.innerHTML = printContents;
		window.print();
	};

	getSendCard = (text, direction) => {
		return <span>{text}</span>;
	};

	connectToLiveAgentFn = (details) => {
		if (this.state.isUserResponsing !== true) this.setState({ isUserResponsing: true });
		console.log('activity', this.state.isUserResponsing);
		this.setState({ name: details.name });
		let id = window.setTimeout(() => {
			this.setState({ isLiveAgent: true }, () => {
				this.connectToLiveAgent(details);
			});
		}, 3000);
		this.setState({ allTimeouts: [...this.state.allTimeouts, id] });
	};

	isWithinTimeRange(startTime, finishTime) {
		const currentTimeUTC = moment().utc();
		const start = moment.utc(startTime, 'HH:mm');
		const finish = moment.utc(finishTime, 'HH:mm');
		return currentTimeUTC.isBetween(start, finish, null, '[]');
	}
	getIsBusinessHour = async () => {
		const weekMappings = {
			1: 'Monday',
			2: 'Tuesday',
			3: 'Wednesday',
			4: 'Thursday',
			5: 'Friday',
			6: 'Saturday',
			7: 'Sunday',
		};
		const res1 = await axios.post(liveagent, { userId: 'agentId', serverType: 'prod' });
		const headers = {
			'X-Auth-Token': _.get(res1, 'data.authToken'),
			'X-User-Id': _.get(res1, 'data.userId'),
			'Content-Type': 'multipart/form-data',
		};
		const { data } = await axios({
			url: `https://liveagentva.dubaichamber.com/api/v1/livechat/business-hour`,
			method: 'GET',
			headers,
		});
		const findCurrentDay = _.find(
			data.businessHour.workHours,
			(each, index) => each.day === _.get(weekMappings, moment().isoWeekday()),
		);
		let [backendHours, backendMin] = findCurrentDay.finish.utc.time.split(':');
		if (backendHours === '00') {
			backendHours = '12';
		}
		const currentHours = moment.utc().format('HH');
		const currentMin = moment.utc().format('MM');
		const backendTotalMin = _.toNumber(backendHours) * 60 + _.toNumber(backendMin);
		const currentTotalHours = _.toNumber(currentHours) * 60 + _.toNumber(currentMin);
		const isOpen = this.isWithinTimeRange(findCurrentDay.start.utc.time, findCurrentDay.finish.utc.time);
		console.log('isOpen', isOpen);
		if (findCurrentDay.day !== 'Friday') {
			const isFridayBreak = currentTotalHours > 480 && currentTotalHours < 600 && findCurrentDay.day === 'Friday';
			this.setState({ isBHOpen: isOpen });
		} else {
			const isFridayBreak = currentTotalHours > 480 && currentTotalHours < 600 && findCurrentDay.day === 'Friday';
			if (isFridayBreak) {
				this.setState({ isBHOpen: currentTotalHours < backendTotalMin && !isFridayBreak });
				console.log(':arrow_right:   ~ getIsBusinessHour= ~ isFridayBreak:', isFridayBreak);
			} else {
				this.setState({ isBHOpen: isOpen });
				console.log(':arrow_right:   ~ getIsBusinessHour= ~ isFridayBreak:', isFridayBreak);
			}
		}
		return _.get(findCurrentDay, 'open', false);
	};

	setFeedbackComponent = () => {
		this.setState({
			chat: [
				...this.state.chat,
				{
					author: 'them',
					type: 'other_message',
					data: {
						text: <Typography.Text>{this.props.t('live_agent_unavailable_hrs')}</Typography.Text>,
					},
				},
				{
					author: 'them',
					type: 'other_message',
					data: {
						text: (
							<QueryForm
								query={query}
								name={this.state.name}
								mobile={this.state.phone}
								email={this.state.email}
								arabicMode={this.state.arabicMode}
								setChat={() => {
									this.setState({
										chat: [
											...this.state.chat,
											{
												author: 'them',
												type: 'text',
												data: {
													text: (
														<span>
															{this.state.arabicMode
																? 'شكرا لك على رسالتك وسنرد قريبا'
																: 'Thank you for your message, we will respond shortly.'}
														</span>
													),
												},
											},
										],
									});
								}}
							/>
						),
					},
				},
			],
		});
	};

	storeFeedbackRatingHandler = async () => {
		try {
			let tomorrow = new Date();
			tomorrow.setDate(tomorrow.getDate());
			if (this.state.question1Rating < 3 && this.state.question2Rating < 3 && this.state.question3Rating < 3) {
				this.setState({ isFeedBackIsLow: true });
			}

			if (this.state.isFeedBackIsLow) {
				let oldChat = [...this.state.chat];
				(oldChat[oldChat.length - 1] = {
					author: 'them',
					type: 'other_message',
					data: {
						text: (
							<div
								style={{
									margin: 'auto',
									borderRadius: '16px',
									boxShadow:
										'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
								}}
							>
								<Card
									style={{ borderRadius: '15px', background: '#f1f1fb' }}
									justifyContent='center'
									direction='column'
									className='feedback-card'
								>
									<Row gutter={[24, 24]} direction='column' justifyContent='space-between'>
										<Col span={24}>
											<Typography.Title style={{ textAlign: 'center' }} level={5}>
												{this.props.t('chat_end_title')}
											</Typography.Title>
										</Col>
										<Col span={24} style={{ marginTop: '5px', textAlign: 'center' }}>
											<Typography.Text className='chatbava_dubaichamberFeedbackQuestion'>
												{this.props.t('chat_end_description')}
											</Typography.Text>
										</Col>
										{!this.state.arabicMode ? (
											<div>
												<Col span={24}>
													<Typography.Text className='chatbava_dubaichamberFeedbackQuestion'>
														1. {translationsEn.feedBackQuestionsOne}
													</Typography.Text>
													<FeedbackSmily
														defaultValue={this.state.question1Rating}
														feedbackSelectHandler={(v) =>
															this.setState({ question1Rating: v })
														}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														style={{
															marginX: '3px',
														}}
														level={3}
														// style={{
														// 	textAlign: 'center',
														// 	paddingLeft: '15px',
														// 	paddingRight: '15px',
														// }}
													>
														2. {translationsEn.feedBackQuestionsSecond}
													</Typography.Text>
													<FeedbackSmily
														defaultValue={this.state.question2Rating}
														feedbackSelectHandler={(v) =>
															this.setState({ question2Rating: v })
														}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														level={4}
														style={{
															marginX: '3px',
														}}
														// style={{
														// 	textAlign: 'center',
														// 	paddingLeft: '15px',
														// 	paddingRight: '15px',
														// }}
													>
														3. {translationsEn.feedBackQuestionsThird}
													</Typography.Text>
													<FeedbackSmily
														defaultValue={this.state.question3Rating}
														feedbackSelectHandler={(v) => {
															this.setFeedbackRatingAvg(v);
															this.setState({ question3Rating: v });
															// this.setState({
															// 	chat: [...this.state.chat],
															// });
														}}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														level={4}
														style={{
															marginX: '3px',
														}}
														// style={{
														// 	textAlign: 'center',
														// 	paddingLeft: '15px',
														// 	paddingRight: '15px',
														// }}
													>
														Thank you for your feedback. We are sorry that your experience
														with us was not satisfactory. Please can you let us know what we
														can do to make your experience better?
													</Typography.Text>

													<Col span={24}>
														<Form
															style={{ width: '100%', marginTop: 4 }}
															name='basic'
															onFinish={async () => {
																// await this.storeFeedbackRatingHandler();
																let isConnectedWithLiveAgent = false;
																if (this.state.isLiveAgent) {
																	isConnectedWithLiveAgent = true;
																}
																let { data } = await axios.post(feedback + '/rating', {
																	question1Rating: this.state.question1Rating,
																	question2Rating: this.state.question2Rating,
																	question3Rating: this.state.question3Rating,
																	language: this.state.arabicMode
																		? 'arabic'
																		: 'english',
																	username: this.state.name || '',
																	isConnectedWithLiveAgent,
																	phoneNumber: this.state.phone || '',
																	email: this.state.email || '',
																	comment: this.state.feedbackUserComment,
																	createdOn: moment(tomorrow).format('DD/MM/YYYY'),
																	// createdOn: moment(new Date()).format('DD/MM/YYYY'),
																});
																await this.setState(
																	{
																		modalVisible: true,
																		chat: [],
																		arabicMode: false,
																		textControl: false,
																	},
																	() => this.props.i18n.changeLanguage('en'),
																);
															}}
															labelCol={{
																span: 6,
															}}
															wrapperCol={{
																span: 18,
															}}
															initialValues={{
																remember: true,
															}}
															// onFinish={onFinish}
															// onFinishFailed={onFinishFailed}
															autoComplete='off'
														>
															<Row>
																<Col span={24}>
																	<Form.Item
																		label='Comment :'
																		name='userComment'
																		rules={[
																			{
																				required: true,
																				message: 'Please Enter Your Comment!',
																			},
																		]}
																	>
																		<TextArea
																			style={{ height: 120 }}
																			placeholder='Enter Your Comment!'
																			onChange={(e) => {
																				this.setState({
																					feedbackUserComment: e.target.value,
																				});
																			}}
																		/>
																	</Form.Item>
																</Col>
																<Col span={24}>
																	<Form.Item className='chatbava_dubaichamberFeedbackSubmitForm'>
																		<Button type='primary' htmlType='submit'>
																			Submit
																		</Button>
																	</Form.Item>
																</Col>
															</Row>
														</Form>
													</Col>
												</Col>
											</div>
										) : (
											<div>
												<Col span={24}>
													<Typography.Text className='chatbava_dubaichamberFeedbackQuestionAr'>
														{translationsAr.feedBackQuestionsOne}
													</Typography.Text>
													<FeedbackSmily
														feedbackSelectHandler={(v) =>
															this.setState({ question1Rating: v })
														}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														style={{
															marginX: '3px',
														}}
														level={3}
													>
														{translationsAr.feedBackQuestionsSecond}
													</Typography.Text>
													<FeedbackSmily
														feedbackSelectHandler={(v) =>
															this.setState({ question2Rating: v })
														}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														level={4}
														style={{
															marginX: '3px',
														}}
													>
														{translationsAr.feedBackQuestionsThird}
													</Typography.Text>
													<FeedbackSmily
														feedbackSelectHandler={(v) =>
															this.setState({ question3Rating: v })
														}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														level={4}
														style={{
															marginX: '3px',
														}}
														// style={{
														// 	textAlign: 'center',
														// 	paddingLeft: '15px',
														// 	paddingRight: '15px',
														// }}
													>
														شكراً لك على ملاحظاتك. نأسف لأن تجربتك معنا لم تكن مُرضية. هل
														يمكنك إخبارنا كيف نجعل تجربتك أفضل؟
													</Typography.Text>

													<Col span={24}>
														<Form
															style={{ width: '100%', marginTop: 4 }}
															name='basic'
															onFinish={async () => {
																// await this.storeFeedbackRatingHandler();
																let { data } = await axios.post(feedback + '/rating', {
																	question1Rating: this.state.question1Rating,
																	question2Rating: this.state.question2Rating,
																	question3Rating: this.state.question3Rating,
																	language: this.state.arabicMode
																		? 'arabic'
																		: 'english',
																	username: this.state.name || '',
																	phoneNumber: this.state.phone || '',
																	email: this.state.email || '',
																	comment: this.state.feedbackUserComment,
																	createdOn: moment(tomorrow).format('DD/MM/YYYY'),
																	// createdOn: moment(new Date()).format('DD/MM/YYYY'),
																});
																await this.setState(
																	{
																		modalVisible: true,
																		chat: [],
																		arabicMode: false,
																		textControl: false,
																	},
																	() => this.props.i18n.changeLanguage('en'),
																);
															}}
															labelCol={{
																span: 6,
															}}
															wrapperCol={{
																span: 18,
															}}
															initialValues={{
																remember: true,
															}}
															// onFinish={onFinish}
															// onFinishFailed={onFinishFailed}
															autoComplete='off'
														>
															<Row>
																<Col span={24}>
																	<Form.Item
																		label=': ملاحظات'
																		name='userComment'
																		rules={[
																			{
																				required: true,
																				message: 'الرجاء إدخال تعليقك!',
																			},
																		]}
																	>
																		<TextArea
																			style={{ height: 120 }}
																			placeholder='أدخل تعليقك!!'
																			onChange={(e) => {
																				this.setState({
																					feedbackUserComment: e.target.value,
																				});
																			}}
																		/>
																	</Form.Item>
																</Col>
																<Col span={24}>
																	<Form.Item className='chatbava_dubaichamberFeedbackSubmitForm'>
																		<Button type='primary' htmlType='submit'>
																			{/* يُقدِّم */}
																			{/* أرسل */}
																			أرسل{' '}
																		</Button>
																	</Form.Item>
																</Col>
															</Row>
														</Form>
													</Col>
												</Col>
											</div>
										)}
									</Row>
								</Card>
							</div>
						),
					},
				}),
					this.setState({
						chat: [...oldChat],
					});
			} else {
				let isConnectedWithLiveAgent = false;
				if (this.state.isLiveAgent) {
					isConnectedWithLiveAgent = true;
				}
				let { data } = await axios.post(feedback + '/rating', {
					question1Rating: this.state.question1Rating,
					question2Rating: this.state.question2Rating,
					question3Rating: this.state.question3Rating,
					language: this.state.arabicMode ? 'arabic' : 'english',
					username: this.state.feedbackUsername,
					createdOn: moment(tomorrow).format('DD/MM/YYYY'),
					isConnectedWithLiveAgent,
					// createdOn: moment(new Date()).format('DD/MM/YYYY'),
				});
				await this.setState(
					{
						modalVisible: true,
						chat: [],
						arabicMode: false,
						textControl: false,
					},
					() => this.props.i18n.changeLanguage('en'),
				);
			}
		} catch (error) {
			console.log('err', error);
		}
	};

	componentDidUpdate(props) {
		console.log('userInactibity', this.state.isUserResponsing);
		if (this.state.isUserResponsing !== 'ended') {
			let totalValue =
				Number(this.state.question1Rating) +
				Number(this.state.question2Rating) +
				Number(this.state.question3Rating);
			if (totalValue < 8) {
				this.setState({ isFeedBackIsLow: true });
				if (!this.state.isFeedBackIsLow) {
					// this.setState({ chat: [...this.state.chat] });
				}
			} else {
				this.setState({ isFeedBackIsLow: false });
				// this.state.isFeedBackIsLow = false;
			}

			// console.log('User did something');
			// if (this.state.isUserResponsing !== 'ended') {
			//

			// }
		}
	}

	internalFormCloseBtnHandler = async () => {
		this.setState({ textControl: true });
		let oldChat = [...this.state.chat];
		oldChat[oldChat.length - 1] = {};
		oldChat[oldChat.length - 2] = {};
		oldChat[oldChat.length - 3] = {};

		this.setState({
			chat: [...oldChat],
			isUserResponsing: 'ending',
		});
	};

	setFeedbackRatingAvg = async (thirdRatingValue) => {
		let totalValue =
			Number(this.state.feedBackQuestionsOne) +
			Number(this.state.feedBackQuestionsSecond) +
			Number(thirdRatingValue);
		if (totalValue < 6 && totalValue !== 0 && totalValue !== null) {
			this.setState({
				chat: [
					...this.state.chat,
					{
						author: 'them',
						type: 'other_message',
						data: {
							text: (
								<div
									className='feedback-wrapper'
									style={{
										display: 'flex',
										justifyContent: 'center',
										width: '100%',
										borderRadius: '16px',
									}}
								>
									<Card
										style={{
											maxWidth: '400px',
											borderRadius: '15px',
											background: '#f1f1fb',
											boxShadow:
												'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
										}}
										justifyContent='center'
										direction='column'
										className='avatar-visibility'
									>
										<Row gutter={[48, 48]} direction='column' justifyContent='space-between'>
											<Col span={24}>
												<Typography.Title style={{ textAlign: 'center' }} level={5}>
													Thanks For your Feedback
												</Typography.Title>
											</Col>
											<Col span={24} style={{ marginTop: '15px' }}>
												<Typography.Text
													style={{
														textAlign: 'center',
														paddingLeft: '15px',
														paddingRight: '15px',
														textAlign: 'center',
													}}
												>
													You Give Low Rating To us Kindly Enter Your Name
												</Typography.Text>
											</Col>

											<Col span={24} style={{ textAlign: 'center', marginTop: '15px' }}>
												<LikeFilled
													style={{ fontSize: '50px', color: '#43cc6c' }}
													onClick={() =>
														this.setState({
															chat: [
																...this.state.chat,
																{
																	author: 'them',
																	type: 'other_message',
																	data: {
																		text: (
																			<Card
																				size='small'
																				title={
																					this.state.arabicMode
																						? 'غرفة دبي'
																						: 'Dubai Chamber Bot'
																				}
																				className='chat-card message-text'
																			>
																				<Col span={24}>
																					<Typography.Text>
																						Thank you for the feedback
																					</Typography.Text>
																				</Col>

																				<Col
																					span={24}
																					className='tick-date text-align-left'
																				>
																					<Typography.Text className='footer-text text-align-left'>
																						{moment().format('hh:ss')}
																					</Typography.Text>
																				</Col>
																			</Card>
																		),
																	},
																},
															],
														})
													}
												/>
												<DislikeFilled
													style={{
														fontSize: '50px',
														marginLeft: '15px',
														color: '#e24650',
													}}
													onClick={() =>
														this.setState({
															chat: [
																...this.state.chat,
																{
																	author: 'them',
																	type: 'text',
																	data: {
																		text: (
																			<Card
																				size='small'
																				title={
																					this.state.arabicMode
																						? 'غرفة دبي'
																						: 'Dubai Chamber Bot'
																				}
																				className='chat-card message-text'
																			>
																				<Col span={24}>
																					<Typography.Text>
																						Thank you for the feedback
																					</Typography.Text>
																				</Col>
																				<Col
																					span={24}
																					className='tick-date text-align-left'
																				>
																					<Typography.Text className='footer-text text-align-left'>
																						{moment().format('hh:ss')}
																					</Typography.Text>
																				</Col>
																			</Card>
																		),
																	},
																},
															],
														})
													}
												/>
											</Col>
											<Col
												span={24}
												style={{
													justifySelf: 'center',
													textAlign: 'center',
													marginTop: '15px',
												}}
											>
												<Button type='link' size='small' onClick={this.downloadTanscript}>
													{this.props.t('transcript')}
												</Button>
											</Col>
										</Row>
									</Card>
								</div>
							),
						},
					},
				],
			});
			console.log('totalValue', totalValue);
			this.setState({ isFeedBackIsLow: true });
		} else {
			this.setState({ isFeedBackIsLow: false });
			// this.state.isFeedBackIsLow = false;
		}
	};

	urlify = (text) => {
		var urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, function(url) {
			return '<a target="_blank" href="' + url + '">' + url + '</a>';
		});
		// or alternatively
		// return text.replace(urlRegex, '<a href="$1">$1</a>')
	};

	setAgentConnection = () => {
		// {
		// 	author: 'them',
		// 	type: 'other_message',
		// 	data: {
		// 		text: <UserDetails connectToLiveAgentFn={this.connectToLiveAgentFn} />,
		// 	},
		// };
		if (this.state.isUserResponsing !== true) this.setState({ isUserResponsing: true });
		console.log('activity', this.state.isUserResponsing);
		this.setState({
			chat: [
				...this.state.chat,
				{
					author: 'them',
					type: 'other_message',
					data: {
						text: <UserDetails connectToLiveAgentFn={this.connectToLiveAgentFn} />,
					},
				},
			],
		});
	};

	greet = async (message, salutation) => {
		if (this.state.isUserResponsing !== true) this.setState({ isUserResponsing: true });
		console.log('activity', this.state.isUserResponsing);
		if (salutation)
			this.setState({
				chat: [
					...this.state.chat,
					{
						author: 'me',
						user: this.state.name,
						type: 'text',
						data: { text: this.getSendCard(salutation, 'me') },
					},
				],
			});

		this.setState({ loading: true });

		const res = await axios.post(this.state.arabicMode ? ENDPOINT_AR : ENDPOINT_EN, {
			message,
			sender_id: this.session_id,
			sender: this.session_id,
		});
		console.log('➡️   ~ res:', res);

		const prev = JSON.parse(sessionStorage.getItem('chat')) || [];

		// sessionStorage.setItem('chat', JSON.stringify([...res.data, ...prev])) //

		const chatRes = _.map(res.data, (eachChat, index) => {
			// const chatRes = _.map(JSON.parse(sessionStorage.getItem('chat')), (eachChat) => {
			this.setState({
				tabs: _.filter(this.state.arabicMode ? TabsArabicJSON : TabsEnglishJSON, [
					'Keyword',
					_.get(eachChat, ['custom', 'context']),
				]),
			});
			if (_.get(eachChat, ['custom', 'payload']) === 'handoff') {
				this.getIsBusinessHour().then((value) => {
					if (!value) {
						this.setFeedbackComponent();
					} else {
						console.log('===== check for the state =====', this.state.isBHOpen, isBH);
						if (!this.state.isBHOpen) {
							// this.setAgentConnection()
							this.setFeedbackComponent();
						} else {
							this.setAgentConnection();
							// this.setFeedbackComponent();
						}
					}
				});

				return null;
			} else if (_.get(eachChat, ['custom', 'payload']) === 'endchat') {
				this.endChat();
				return null;
			} else if (_.get(eachChat, ['custom', 'payload']) === 'carousel') {
				return {
					author: 'them',
					type: 'other_message',
					data: {
						text: (
							<Slider {...settings}>
								{_.map(_.get(eachChat, ['custom', 'data']), (each) => (
									<ImagePreview data={[each]} />
								))}
							</Slider>
						),
					},
				};
			} else if (_.get(eachChat, ['custom', 'payload']) === 'buttons') {
				return {
					author: 'them',
					type: 'other_message',
					data: {
						text: <Buttons sendMessage={this.greet} data={_.get(eachChat, ['custom', 'data'])} />,
					},
				};
			} else if (_.get(eachChat, ['custom', 'payload']) === 'authentication_form') {
				this.setState({ textControl: false });
				return {
					author: 'them',
					type: 'other_message',
					data: {
						text: (
							<InternalForm
								onCloseBtnHandler={this.internalFormCloseBtnHandler}
								// endChat={this.endChat}
								setState={this.setState}
								sendMessage={this.greet}
								arabicMode={this.state.arabicMode}
							></InternalForm>
						),
					},
				};
			} else if (
				_.get(eachChat, ['custom', 'payload']) === 'true_copy_listing' ||
				_.get(eachChat, ['custom', 'payload']) === 'verify_signature_listing' ||
				_.get(eachChat, ['custom', 'payload']) === 'authenticate_certificate_listing'
			) {
				const paylodList = _.get(eachChat, ['custom', 'payload']);
				const filteredListing = _.get(services, [paylodList]);
				return {
					author: 'them',
					type: 'other_message',
					data: {
						text: <ListTable sendMessage={this.greet} list={filteredListing} />,
					},
				};
			} else if (_.get(eachChat, ['custom', 'payload']) === 'buttons_disable') {
				return {
					author: 'them',
					type: 'other_message',
					data: {
						text: <ButtonsDisable sendMessage={this.greet} data={_.get(eachChat, ['custom', 'data'])} />,
					},
				};
			} else if (_.get(eachChat, ['custom', 'payload']) === 'image') {
				return {
					author: 'them',
					type: 'other_message',
					data: {
						text: <ImagePreview data={_.get(eachChat, ['custom', 'data'])} />,
					},
				};
			} else if (_.get(eachChat, ['custom', 'payload']) === 'service_list') {
				return {
					author: 'them',
					type: 'other_message',
					data: {
						text: <ServiceList data={_.get(eachChat, ['custom', 'data'])} sendMessage={this.greet} />,
					},
				};
			} else if (_.get(eachChat, ['custom', 'payload']) === 'upload_file') {
				return {
					author: 'them',
					type: 'other_message',
					data: {
						text: (
							<UploadFile
								data={_.get(eachChat, ['custom', 'data'])}
								sendMessage={this.greet}
								arabicMode={this.state.arabicMode}
							/>
						),
					},
				};
			} else if (_.get(eachChat, ['custom', 'payload']) === 'feedback') {
				this.setState({ textControl: false });
				this.greet('/restart');

				return {
					author: 'them',
					type: 'other_message',
					data: {
						text: (
							<div
								className='feedback-wrapper'
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%',
									borderRadius: '16px',
								}}
							>
								<Card
									style={{
										maxWidth: '400px',
										borderRadius: '15px',
										background: '#f1f1fb',
										boxShadow:
											'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
									}}
									justifyContent='center'
									direction='column'
									className='avatar-visibility'
								>
									<Row gutter={[48, 48]} direction='column' justifyContent='space-between'>
										<Col span={24}>
											<Typography.Title style={{ textAlign: 'center' }} level={5}>
												{this.props.t('chat_end_title')}
											</Typography.Title>
										</Col>
										<Col span={24} style={{ marginTop: '15px' }}>
											<Typography.Text
												style={{
													textAlign: 'center',
													paddingLeft: '15px',
													paddingRight: '15px',
													textAlign: 'center',
												}}
											>
												{this.props.t('chat_end_description')}
											</Typography.Text>
										</Col>

										<Col span={24} style={{ textAlign: 'center', marginTop: '15px' }}>
											<LikeFilled
												style={{ fontSize: '50px', color: '#43cc6c' }}
												onClick={() =>
													this.setState({
														chat: [
															...this.state.chat,
															{
																author: 'them',
																type: 'text',
																data: {
																	text: (
																		<Card
																			size='small'
																			title={
																				this.state.arabicMode
																					? 'غرفة دبي'
																					: 'Dubai Chamber Bot'
																			}
																			className='chat-card message-text'
																		>
																			<Col span={24}>
																				<Typography.Text>
																					Thank you for the feedback
																				</Typography.Text>
																			</Col>

																			<Col
																				span={24}
																				className='tick-date text-align-left'
																			>
																				<Typography.Text className='footer-text text-align-left'>
																					{moment().format('hh:ss')}
																				</Typography.Text>
																			</Col>
																		</Card>
																	),
																},
															},
														],
													})
												}
											/>
											<DislikeFilled
												style={{
													fontSize: '50px',
													marginLeft: '15px',
													color: '#e24650',
												}}
												onClick={() =>
													this.setState({
														chat: [
															...this.state.chat,
															{
																author: 'them',
																type: 'text',
																data: {
																	text: (
																		<Card
																			size='small'
																			title={
																				this.state.arabicMode
																					? 'غرفة دبي'
																					: 'Dubai Chamber Bot'
																			}
																			className='chat-card message-text'
																		>
																			<Col span={24}>
																				<Typography.Text>
																					Thank you for the feedback
																				</Typography.Text>
																			</Col>
																			<Col
																				span={24}
																				className='tick-date text-align-left'
																			>
																				<Typography.Text className='footer-text text-align-left'>
																					{moment().format('hh:ss')}
																				</Typography.Text>
																			</Col>
																		</Card>
																	),
																},
															},
														],
													})
												}
											/>
										</Col>
										<Col
											span={24}
											style={{
												justifySelf: 'center',
												textAlign: 'center',
												marginTop: '15px',
											}}
										>
											<Button type='link' size='small' onClick={this.downloadTanscript}>
												{this.props.t('transcript')}
											</Button>
										</Col>
									</Row>
								</Card>
							</div>
						),
					},
				};
			} else if (_.includes(_.keys(_.get(eachChat, ['custom'])), 'context')) {
				return null;
			} else {
				this.setState({ textControl: true });
				return {
					author: 'them',
					user: this.state.arabicMode ? 'غرفة دبي' : 'Dubai Chamber Bot',
					type: 'text',
					data: {
						text: (
							<div
								className='text-message-recieve'
								dangerouslySetInnerHTML={{ __html: eachChat.text }}
							></div>
						),
					},
				};
			}
		});

		this.setState({ loading: false });

		_.forEach(
			_.filter(chatRes, (each) => each),
			(each, index) => {
				setTimeout(() => {
					// const element = { ...each, data: { text: JSON.stringify(each.data.text) } }
					// setItemInSession(element)
					this.setState({ chat: [...this.state.chat, each] });
				}, 1000 * index);
			},
		);

		document.getElementById('message-input').focus();
	};

	sendMessage = (message) => {
		// if (this.state.isLiveAgentConnected) {
		// if (this.state.isLiveAgent == true) {
		// 	setTimeout(() => {
		// 		this.sendUserEndChatWarning();
		// 	}, 1000 * 30 * 1);
		// }
		this.setState({ isUserResponsing: true });
		let time = new Date();
		time.setSeconds(time.getSeconds() + 30);
		// this.setState({ lastUserActiveTs: time.getTime() });
		const _id = cryptoRandomString({ length: 10, type: 'alphanumeric' });
		if (this.state.isLiveAgent && this.state.isLiveAgentConnected) {
			this.realTimeAPI.sendMessage({
				msg: 'method',
				method: 'sendMessageLivechat',
				params: [
					{
						_id,
						rid,
						msg: _.get(message, 'data.text'),
						token,
					},
				],
				id: '11',
			});
		} else {
			this.greet(_.get(message, 'data.text'), _.get(message, 'data.text'));
		}
	};

	endChat = (byAgent = false) => {
		const mid = cryptoRandomString({ length: 10, type: 'alphanumeric' });
		if (this.state.isLiveAgent) {
			if (!byAgent) {
				this.realTimeAPI.sendMessage({
					msg: 'method',
					method: 'sendMessageLivechat',
					params: [
						{
							_id: mid,
							rid,
							msg: `${this.state.name} has ended this conversation.`,
							token,
						},
					],
					id: '11',
				});

				this.realTimeAPI.sendMessage({
					msg: 'unsub',
					id: sid,
					name: 'stream-room-messages',
					params: [rid, true],
				});

				this.realTimeAPI.sendMessage({
					msg: 'method',
					method: 'eraseRoom',
					id: '92',
					params: [rid],
				});
			}
			this.realTimeAPI.disconnect();
		}

		this.setState({
			isLiveAgent: false,
			textControl: false,
			showForm: false,
			chat: [
				...this.state.chat,
				{
					author: 'them',
					type: 'other_message',
					data: {
						text: (
							<div
								style={{
									margin: 'auto',
									borderRadius: '16px',
									boxShadow:
										'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
								}}
							>
								<Card
									style={{ borderRadius: '15px', background: '#f1f1fb' }}
									justifyContent='center'
									direction='column'
									className='feedback-card'
								>
									<Row gutter={[24, 24]} direction='column' justifyContent='space-between'>
										<Col span={24}>
											<Typography.Title style={{ textAlign: 'center' }} level={5}>
												{this.props.t('chat_end_title')}
											</Typography.Title>
										</Col>
										<Col span={24} style={{ marginTop: '5px', textAlign: 'center' }}>
											<Typography.Text className='chatbava_dubaichamberFeedbackQuestion'>
												{this.props.t('chat_end_description')}
											</Typography.Text>
										</Col>
										{!this.state.arabicMode ? (
											<div>
												<Col span={24}>
													<Typography.Text className='chatbava_dubaichamberFeedbackQuestion'>
														1. {translationsEn.feedBackQuestionsOne}
													</Typography.Text>
													<FeedbackSmily
														feedbackSelectHandler={(v) =>
															this.setState({ question1Rating: v })
														}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														style={{
															marginX: '3px',
														}}
														level={3}
														// style={{
														// 	textAlign: 'center',
														// 	paddingLeft: '15px',
														// 	paddingRight: '15px',
														// }}
													>
														2. {translationsEn.feedBackQuestionsSecond}
													</Typography.Text>
													<FeedbackSmily
														feedbackSelectHandler={(v) =>
															this.setState({ question2Rating: v })
														}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														level={4}
														style={{
															marginX: '3px',
														}}
														// style={{
														// 	textAlign: 'center',
														// 	paddingLeft: '15px',
														// 	paddingRight: '15px',
														// }}
													>
														3. {translationsEn.feedBackQuestionsThird}
													</Typography.Text>
													<FeedbackSmily
														feedbackSelectHandler={(v) => {
															this.setFeedbackRatingAvg(v);
															this.setState({ question3Rating: v });
															this.setState({
																chat: [...this.state.chat],
															});
														}}
													/>
												</Col>
												<Col
													span={24}
													className='chatbava_dubaichamberFeedbackEnterUName'
												></Col>
												<Col span={24} style={{ display: 'flex' }}>
													<Col span={24}>
														<Button
															type='primary'
															onClick={async () => {
																await this.storeFeedbackRatingHandler();
																// await this.setState(
																// 	{
																// 		modalVisible: true,
																// 		chat: [],
																// 		arabicMode: false,
																// 		textControl: false,
																// 	},
																// 	() => this.props.i18n.changeLanguage('en'),
																// );
															}}
															htmlType='submit'
															style={{ width: '100%' }}
														>
															{'Submit'}
														</Button>
													</Col>
												</Col>
											</div>
										) : (
											<div cla>
												<Col span={24}>
													<Typography.Text className='chatbava_dubaichamberFeedbackQuestionAr'>
														1.{translationsAr.feedBackQuestionsOne}
													</Typography.Text>
													<FeedbackSmily
														feedbackSelectHandler={(v) =>
															this.setState({ question1Rating: v })
														}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														style={{
															marginX: '3px',
														}}
														level={3}
													>
														2. {translationsAr.feedBackQuestionsSecond}
													</Typography.Text>
													<FeedbackSmily
														feedbackSelectHandler={(v) =>
															this.setState({ question2Rating: v })
														}
													/>
												</Col>
												<Col span={24}>
													<Typography.Text
														className='chatbava_dubaichamberFeedbackQuestion'
														level={4}
														style={{
															marginX: '3px',
														}}
													>
														3. {translationsAr.feedBackQuestionsThird}
													</Typography.Text>
													<FeedbackSmily
														feedbackSelectHandler={(v) =>
															this.setState({ question3Rating: v })
														}
													/>
												</Col>

												<Col span={24} style={{ display: 'flex' }}>
													<Col span={24}>
														<Button
															type='primary'
															onClick={async () => {
																await this.storeFeedbackRatingHandler();
																// await this.setState(
																// 	{
																// 		modalVisible: true,
																// 		chat: [],
																// 		arabicMode: false,
																// 		textControl: false,
																// 	},
																// 	() => this.props.i18n.changeLanguage('en'),
																// );
															}}
															htmlType='submit'
															style={{ width: '100%' }}
														>
															{/* {'يُقدِّم'} */}
															أرسل
														</Button>
													</Col>
												</Col>
											</div>
										)}
										<Col
											span={24}
											style={{ justifySelf: 'center', textAlign: 'center', marginTop: '15px' }}
										>
											<Button
												type='link'
												size='small'
												onClick={() => {
													this.setState(
														{
															modalVisible: true,
															isLiveAgent: false,
															isLiveAgentConnected: false,
															lastUserActiveTs: false,
															isUserResponsing: null,
															chat: [],
															arabicMode: false,
															textControl: false,
														},
														() => this.props.i18n.changeLanguage('en'),
													);
												}}
											>
												{this.props.t('transcript')}
											</Button>
										</Col>
									</Row>
								</Card>
							</div>
						),
					},
				},
			],
		});
		this.greet('/restart');
	};

	sendUserEndChatWarning = (isFirstChat = false) => {
		// console.log(
		// 	'time',
		// 	this.state.lastUserActiveTs < new Date().getTime(),
		// 	this.state.lastUserActiveTs,
		// 	new Date().getTime(),
		// );
		if (isFirstChat && this.state.isUserResponsing === true) {
			return;
		}
		// const mid = cryptoRandomString({ length: 10, type: 'alphanumeric' });

		if (
			this.state.isLiveAgent &&
			this.state.isUserResponsing !== true &&
			this.state.lastUserActiveTs < new Date().getTime()
		) {
			this.setState({ textControl: true });
			console.log('isLiveAgentConnected', this.state.isLiveAgentConnected);
			let oldChat = [...this.state.chat];
			oldChat[oldChat.length] = {
				author: 'them',
				type: 'other_message',
				data: {
					text: (
						<div
							style={{
								margin: 'auto',
								borderRadius: '16px',
								boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
							}}
						>
							<Card
								style={{ borderRadius: '15px', background: '#f1f1fb' }}
								justifyContent='center'
								direction='column'
								className='feedback-card'
							>
								<Row gutter={[24, 24]} direction='column' justifyContent='space-between'>
									<Col span={24}>
										{!this.state.arabicMode ? (
											<Typography.Title style={{ textAlign: 'center' }} level={5}>
												{this.state.isLiveAgentConnected
													? `It appears that you haven't typed in a long, therefore this conversation
												will end in less than a minute. In the absence of activity, chat will be
												closed.`
													: `We apologize, All our agents are busy assisting other customers at this moment. Please wait for a while or try to connect after sometime.`}
											</Typography.Title>
										) : (
											<Typography.Title style={{ textAlign: 'center' }} level={5}>
												{this.state.isLiveAgentConnected
													? translationsAr.visitorNotResponsingWarning
													: translationsAr.agentNotAvailableWarning}
											</Typography.Title>
										)}
									</Col>
								</Row>
							</Card>
						</div>
					),
				},
			};

			this.setState({
				chat: [...oldChat],
				isUserResponsing: 'ending',
			});
			if (this.state.isLiveAgentConnected) {
				this.setState({ textControl: true });
				setTimeout(() => {
					if (this.state.isLiveAgentConnected && this.state.isUserResponsing === 'ending')
						this.userIsNotResEndChat();
				}, 1000 * 60 * 5);
			} else {
				setTimeout(() => {
					if (this.state.isLiveAgent) this.userIsNotResEndChat();
				}, 1000 * 60 * 5); // For Those visitors who don't have a live agent
			}
		}
		// this.greet('/restart');
	};

	sendAgentIsTakingTime = () => {
		// const mid = cryptoRandomString({ length: 10, type: 'alphanumeric' });
		if (!this.state.isLiveAgentConnected) {
			// if (this.state.isLiveAgent && this.state.lastUserActiveTs < new Date().getTime()) {
			console.log('isLiveAgentConnected', this.state.isLiveAgentConnected);
			this.setState({ textControl: true });
			let oldChat = [...this.state.chat];
			oldChat[oldChat.length] = {
				author: 'them',
				type: 'other_message',
				data: {
					text: (
						<div
							style={{
								margin: 'auto',
								borderRadius: '16px',
								boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
							}}
						>
							<Card
								style={{ borderRadius: '15px', background: '#f1f1fb' }}
								justifyContent='center'
								direction='column'
								className='feedback-card'
							>
								<Row gutter={[24, 24]} direction='column' justifyContent='space-between'>
									<Col span={24}>
										{!this.state.arabicMode ? (
											<Typography.Title style={{ textAlign: 'center' }} level={5}>
												{this.state.isLiveAgentConnected
													? `It appears that you haven't typed in a long, therefore this conversation
												will end in less than a minute. In the absence of activity, chat will be
												closed.`
													: `We apologize, All our agents are busy assisting other customers at this moment. Please wait for a while or try to connect after sometime.`}
											</Typography.Title>
										) : (
											<Typography.Title style={{ textAlign: 'center' }} level={5}>
												{this.state.isLiveAgentConnected
													? translationsAr.visitorNotResponsingWarning
													: translationsAr.agentNotAvailableWarning}
											</Typography.Title>
										)}
									</Col>
								</Row>
							</Card>
						</div>
					),
				},
			};

			this.setState({
				chat: [...oldChat],
				isUserResponsing: 'ending',
			});
			if (this.state.isLiveAgentConnected) {
				this.setState({ textControl: true });
				setTimeout(() => {
					if (this.state.isLiveAgentConnected && this.state.isUserResponsing === 'ending')
						this.userIsNotResEndChat();
				}, 1000 * 60 * 5);
			} else {
				setTimeout(() => {
					if (this.state.isLiveAgent) this.userIsNotResEndChat();
				}, 1000 * 60 * 5); // For Those visitors who don't have a live agent
			}
		}
		// this.greet('/restart');
	};

	closeRoonHadnler = async () => {
		const res1 = await axios.post(liveagent, { userId: 'agentId', serverType });
		const headers = {
			'X-Auth-Token': _.get(res1, 'data.authToken'),
			'X-User-Id': _.get(res1, 'data.userId'),
			'Content-Type': 'multipart/form-data',
		};

		const checkRoom = await axios({
			url: liveagent_Dev + `v1/livechat/room?token=${token}&rid=${rid}&agentId=Y67yEuaJCzXetNcvM`,
			method: 'GET',
			headers,
		});

		const roomInfoWaitingResponse = _.get(checkRoom, 'data.room.waitingResponse', null);
		const roomInfoservedBy = _.get(checkRoom, 'data.room.servedBy', null);
		// console.log('roomInfoWaitingResponse');
		if (roomInfoWaitingResponse === true && !roomInfoservedBy) {
			const res = await axios.post(roomCloseUrl, {
				rid,
				token,
			});
		}
	};

	userIsNotResEndChat = async () => {
		console.log('userIsNotResEndChatF');
		this.state.isUserResponsing = 'ending';
		if (this.state.isUserResponsing === 'ending') {
			const mid = cryptoRandomString({ length: 10, type: 'alphanumeric' });
			if (this.state.isLiveAgent) {
				this.realTimeAPI.sendMessage({
					msg: 'method',
					method: 'sendMessageLivechat',
					params: [
						{
							_id: mid,
							rid,
							chatAbandoned: true,
							msg: `Chat Abandoned By The User`,
							token,
						},
					],
					id: '11',
				});

				this.realTimeAPI.sendMessage({
					msg: 'unsub',
					id: sid,
					name: 'stream-room-messages',
					params: [rid, true],
				});

				// this.realTimeAPI.sendMessage({
				// 	msg: 'method',
				// 	method: 'eraseRoom',
				// 	id: '92',
				// 	params: [rid],
				// });
				this.realTimeAPI.disconnect();
			}

			let oldChat = [...this.state.chat];
			oldChat[oldChat.length - 1] = {
				author: 'them',
				type: 'other_message',
				data: {
					text: (
						<div
							style={{
								margin: 'auto',
								borderRadius: '16px',
								boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
							}}
						>
							<Card
								style={{ borderRadius: '15px', background: '#f1f1fb' }}
								justifyContent='center'
								direction='column'
								className='feedback-card'
							>
								<Row gutter={[24, 24]} direction='column' justifyContent='space-between'>
									<Col span={24}>
										{!this.state.arabicMode ? (
											<Typography.Title style={{ textAlign: 'center' }} level={5}>
												{this.state.isLiveAgentConnected
													? 'This chat has ended because you did not give any response.'
													: 'This chat has ended because no agent is available at this moment. Kindly, try to connect in some time.'}
											</Typography.Title>
										) : (
											<Typography.Title style={{ textAlign: 'center' }} level={5}>
												{this.state.isLiveAgentConnected
													? translationsAr.visitorNotResponsing
													: translationsAr.agentNotAvailableMsg}
											</Typography.Title>
										)}
									</Col>
									<Col span={24} style={{ marginTop: '5px', textAlign: 'center' }}>
										<Typography.Text className='chatbava_dubaichamberFeedbackQuestion'>
											{this.props.t('chat_end_description')}
										</Typography.Text>
									</Col>
									{!this.state.arabicMode ? (
										<div>
											<Col span={24}>
												<Typography.Text className='chatbava_dubaichamberFeedbackQuestion'>
													1. {translationsEn.feedBackQuestionsOne}
												</Typography.Text>
												<FeedbackSmily
													feedbackSelectHandler={(v) => this.setState({ question1Rating: v })}
												/>
											</Col>
											<Col span={24}>
												<Typography.Text
													className='chatbava_dubaichamberFeedbackQuestion'
													style={{
														marginX: '3px',
													}}
													level={3}
													// style={{
													// 	textAlign: 'center',
													// 	paddingLeft: '15px',
													// 	paddingRight: '15px',
													// }}
												>
													2. {translationsEn.feedBackQuestionsSecond}
												</Typography.Text>
												<FeedbackSmily
													feedbackSelectHandler={(v) => this.setState({ question2Rating: v })}
												/>
											</Col>
											<Col span={24}>
												<Typography.Text
													className='chatbava_dubaichamberFeedbackQuestion'
													level={4}
													style={{
														marginX: '3px',
													}}
													// style={{
													// 	textAlign: 'center',
													// 	paddingLeft: '15px',
													// 	paddingRight: '15px',
													// }}
												>
													3. {translationsEn.feedBackQuestionsThird}
												</Typography.Text>
												<FeedbackSmily
													feedbackSelectHandler={(v) => {
														this.setFeedbackRatingAvg(v);
														this.setState({ question3Rating: v });
														this.setState({
															chat: [...this.state.chat],
														});
													}}
												/>
											</Col>
											<Col span={24} className='chatbava_dubaichamberFeedbackEnterUName'></Col>
											<Col span={24} style={{ display: 'flex' }}>
												<Col span={24}>
													<Button
														type='primary'
														onClick={async () => {
															await this.storeFeedbackRatingHandler();
															// await this.setState(
															// 	{
															// 		modalVisible: true,
															// 		chat: [],
															// 		arabicMode: false,
															// 		textControl: false,
															// 	},
															// 	() => this.props.i18n.changeLanguage('en'),
															// );
														}}
														htmlType='submit'
														style={{ width: '100%' }}
													>
														{'Submit'}
													</Button>
												</Col>
											</Col>
										</div>
									) : (
										<div cla>
											<Col span={24}>
												<Typography.Text className='chatbava_dubaichamberFeedbackQuestionAr'>
													{translationsAr.feedBackQuestionsOne}
												</Typography.Text>
												<FeedbackSmily
													feedbackSelectHandler={(v) => this.setState({ question1Rating: v })}
												/>
											</Col>
											<Col span={24}>
												<Typography.Text
													className='chatbava_dubaichamberFeedbackQuestion'
													style={{
														marginX: '3px',
													}}
													level={3}
												>
													{translationsAr.feedBackQuestionsSecond}
												</Typography.Text>
												<FeedbackSmily
													feedbackSelectHandler={(v) => this.setState({ question2Rating: v })}
												/>
											</Col>
											<Col span={24}>
												<Typography.Text
													className='chatbava_dubaichamberFeedbackQuestion'
													level={4}
													style={{
														marginX: '3px',
													}}
												>
													{translationsAr.feedBackQuestionsThird}
												</Typography.Text>
												<FeedbackSmily
													feedbackSelectHandler={(v) => this.setState({ question3Rating: v })}
												/>
											</Col>

											<Col span={24} style={{ display: 'flex' }}>
												<Col span={24}>
													<Button
														type='primary'
														onClick={async () => {
															await this.storeFeedbackRatingHandler();
															// await this.setState(
															// 	{
															// 		modalVisible: true,
															// 		chat: [],
															// 		arabicMode: false,
															// 		textControl: false,
															// 	},
															// 	() => this.props.i18n.changeLanguage('en'),
															// );
														}}
														htmlType='submit'
														style={{ width: '100%' }}
													>
														{/* {'يُقدِّم'} */}
														أرسل
													</Button>
												</Col>
											</Col>
										</div>
									)}
									<Col
										span={24}
										style={{ justifySelf: 'center', textAlign: 'center', marginTop: '15px' }}
									>
										<Button
											type='link'
											size='small'
											onClick={() => {
												this.setState(
													{
														modalVisible: true,
														chat: [],
														arabicMode: false,
														isLiveAgent: false,
														isLiveAgentConnected: false,
														lastUserActiveTs: false,
														isUserResponsing: null,
														textControl: false,
													},
													() => this.props.i18n.changeLanguage('en'),
												);
											}}
										>
											{this.props.t('transcript')}
										</Button>
									</Col>
								</Row>
							</Card>
						</div>
					),
				},
			};
			this.closeRoonHadnler();
			this.setState({ chat: [...oldChat], isUserResponsing: 'ended', isLiveAgent: false, textControl: false });
			// this.setState(
			// 	{
			// 		modalVisible: true,
			// 		chat: [],
			// 		arabicMode: false,
			// 		textControl: false,
			// 	},
			// 	() => this.props.i18n.changeLanguage('en'),
			// );
		}
	};

	renderModalContent = () => {
		// if (this.state.isUserResponsing !== true) this.setState({ isUserResponsing: true });
		// console.log('activity', this.state.isUserResponsing);
		if (this.state.showForm) {
			return (
				<WelcomeFrom
					arabicMode={this.state.arabicMode}
					sendMessage={(message, values) => {
						const { name, phone, email } = values;
						this.setState({ name, email, phone, textControl: true });
						this.greet(message.data.text, undefined);
						axios.post(chatbot_user, { name, mobile: phone, email });
					}}
					closeModal={() => this.setState({ modalVisible: false })}
					closeWindow={() => {
						this.myRef.current.handleClick();
					}}
				/>
			);
		} else {
			return (
				<WelcomeScreen
					arabicMode={this.state.arabicMode}
					changeLanguage={(lang) => {
						this.setState({ arabicMode: lang === 'en' ? false : true });
						this.props.i18n.changeLanguage(lang);
					}}
					showForm={() => {
						const greetMsg = this.state.arabicMode ? 'أهلا' : 'Hi';
						this.greet(greetMsg, undefined);
						this.setState({ showForm: false, modalVisible: false });
					}}
					closeWindow={() => {
						this.myRef.current.handleClick();
					}}
				/>
			);
		}
	};

	onFileAttach = (file) => {
		if (this.state.isUserResponsing !== true) this.setState({ isUserResponsing: true });
		console.log('activity', this.state.isUserResponsing);
		console.log('====ffiee=====', file);
		this.setState({
			chat: [
				...this.state.chat,
				{
					author: 'me',
					type: 'text',
					user: this.state.name,
					data: {
						text: (
							<Card
								size='small'
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									columnGap: '1em',
								}}
							>
								<FileTextOutlined className='upload-file-icon' />
								<div>
									<Typography.Text>{file.name}</Typography.Text>
								</div>
							</Card>
						),
					},
				},
			],
		});
	};
	render() {
		const modeClass = this.state.arabicMode ? ' arabic-mode' : ' english-mode';

		return (
			<div
				className={
					this.state.isLiveAgent
						? 'agent-icon chatbot-main scrollbar' + modeClass
						: 'bot-icon chatbot-main scrollbar' + modeClass
				}
				id='chatbot-parent'
				dir={this.state.arabicMode ? 'rtl' : 'ltr'}
			>
				{/* <FeedbackSmily /> */}
				<Launcher
					onEndChat={this.endChat}
					agentProfile={{
						teamName: this.props.t('headerTitle'),
					}}
					loading={this.state.loading}
					ref={this.myRef}
					onMessageWasSent={this.sendMessage}
					messageList={this.state.chat}
					// messageList={getItemInSession()}
					downloadTanscript={this.downloadTanscript}
					arabicMode={this.state.arabicMode}
					modalVisible={this.state.modalVisible}
					onAudioInput={this.sendMessage}
					textControl={this.state.textControl}
					tabs={this.state.tabs}
					showMobileTabs={() => this.setState({ showMobileTab: !this.state.showMobileTab })}
					modalContent={this.renderModalContent()}
					sendMessage={this.greet}
					chat={this.state.chat}
					onFileAttach={this.onFileAttach}
				/>
				<Modal
					title={
						this.state.arabicMode
							? `لوحة المعلومات`
							: `Information Panel - (${_.upperFirst(_.get(this.state.tabs, [0, 'Keyword']))})`
					}
					centered
					visible={this.state.showMobileTab}
					onCancel={() => this.setState({ showMobileTab: !this.state.showMobileTab })}
					className={this.state.arabicMode ? 'mobile-modal arabic-modal' : 'mobile-modal'}
				>
					<div className='padding-sidebar-mobile'>
						<div className='sidebar-wrapper-mobile'>
							<Collapse accordion>
								{_.map(_.filter(_.slice(this.state.tabs, 0, 4), 'label'), (each, index) => (
									<Panel header={each.label} key={_.toString(index)}>
										<div
											className='links-content'
											dangerouslySetInnerHTML={{ __html: _.get(each, 'Answer', '') }}
										></div>
									</Panel>
								))}
							</Collapse>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

export default withTranslation()(App);
