import React from 'react';
import { Form, Row, Col, Typography, Input, Button } from 'antd';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import countries from '../../constants/countries_ar.json';
import i18n from 'i18next';

import { initReactI18next, withTranslation } from 'react-i18next';
import _ from 'lodash';
import { translationsAr, translationsEn } from '../../Utils/constant';

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: { translation: translationsEn },
			ar: { translation: translationsAr },
		},
		lng: 'en',
		fallbackLng: 'en',
		interpolation: { escapeValue: false },
	});

const WelcomeForm = (props) => {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		console.log(form.isFieldTouched('phone'));
		form.validateFields().then(() => {
			props.closeModal();
			const formString = props.arabicMode ? ` معلوماتي هي [${name}]` : `My info is ${_.upperFirst(values.name)}`;
			props.sendMessage({ data: { text: formString } }, values);
			// this.greet(formString, this.state.arabicMode ? 'تم التزويد بالمعلومات' : 'Information provided');
		});
	};

	return (
		<div className='welcome-from-wrapper'>
			<div className='item-1'>
				<h1>{props.t('greet')}</h1>
				<span>{props.t('provide_info')}</span>
			</div>
			<div className='item-2'>
				<Form name='basic' form={form} onFinish={onFinish} initialValues={{ phone: undefined }}>
					<Row gutter={[12, 12]}>
						<Col span={24}>
							<Typography.Text className='form-text'>
								{/* {state.arabicMode
                                    ? 'الرجاء مشاركة بعض المعلومات عن نفسك'
                                    : 'Please share some information about yourself'} */}
							</Typography.Text>
						</Col>
						<Col span={24}>
							<Form.Item
								name='name'
								rules={[
									{
										required: true,
										message: props.t('name_error'),
									},
								]}
							>
								<Input className='chat-input' placeholder={props.t('name')} />
							</Form.Item>
						</Col>
						<Col span={24} className='phone-error'>
							<Form.Item
								name='phone'
								rules={[
									{
										required: false,
									},
									() => ({
										validator: (rule, value, callback) => {
											if (form.isFieldTouched('phone')) {
												const allValues = form.getFieldsValue();
												const isValid = isValidPhoneNumber(allValues.phone);
												if (!isValid) {
													return Promise.reject(new Error(props.t('phone_error')));
												}
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<PhoneInput
									defaultCountry='AE'
									placeholder={props.t('phone') + ' (Optional)'}
									labels={props.arabicMode ? countries : undefined}
									onChange={(value) => {
										const allValues = form.getFieldsValue();
										form.setFieldsValue({
											...allValues,
											phone: value,
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={24}>
							<Form.Item
								name='email'
								rules={[
									{
										message: props.t('email_error'),
										type: 'email',
									},
								]}
							>
								<Input placeholder={props.t('email') + ' (Optional)'} className='chat-input' />
							</Form.Item>
						</Col>
						<Col span={24} style={{ height: '40px' }}>
							<Form.Item>
								<Button
									htmlType='submit'
									shape='round'
									size='large'
									// onClick={onFinish}
									style={{
										background: '#0a3b5c',
										float: 'right',
										border: '0px',
										color: '#ffff',
										width: '100%',
									}}
								>
									{props.t('submit')}
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};

export default withTranslation()(WelcomeForm);
