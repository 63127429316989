import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MessageList from '../../components/MessageList';
import UserInput from '../../components/UserInput';
import Header from '../../components/Header';
import { Row, Col, Collapse, Card, Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import _ from 'lodash';

const { Panel } = Collapse;

class ChatWindowMobile extends Component {
	constructor(props) {
		super(props);
	}

	onUserInputSubmit(message) {
		this.props.onUserInputSubmit(message);
	}

	onFilesSelected(filesList) {
		this.props.onFilesSelected(filesList);
	}

	render() {
		let messageList = this.props.messageList || [];
		let classList = ['sc-chat-window', this.props.isOpen ? 'opened' : 'closed'];
		//showMobileTabs
		return (
			<div className={classList.join(' ')}>
				{this.props.modalVisible ? (
					<div className='chat-modal welcome-message-wrapper'>
						{this.props.modalContent}
						<Button
							shape='circle'
							size='small'
							className='header-button minimize'
							onClick={() => this.props.handleClick()}
						>
							<MinusOutlined style={{ fontSize: '12px' }} />
						</Button>
					</div>
				) : (
					<Row className='row-wrapper'>
						<Col span={24}>
							<Header
								downloadTanscript={this.props.downloadTanscript}
								onEndChat={this.props.onEndChat}
								teamName={this.props.agentProfile.teamName}
								imageUrl={this.props.agentProfile.imageUrl}
								onClose={this.props.onClose}
								arabicMode={this.props.arabicMode}
								handleClick={this.props.handleClick}
								showMobileTabs={this.props.showMobileTabs}
								textControl={this.props.textControl}
							/>
							<Card className='sub-header-wrapper'>
								<div className='sub-header'>
									<span>
										{this.props.arabicMode
											? 'ساعات العمل: من الاثنين إلى الخميس من الساعة 8 صباحًا حتى 4:30 عصرًا. يوم الجمعة من الساعة 8 صباحًا حتى 12 ظهرًا، ثم من الساعة 2 ظهرًا حتى 4:30 عصرًا بعد الاستراحة.'
											: 'Our working hours are from 08:00 am to 04:30 pm Monday to Thursday. Friday 08:00 am to 12:00 pm and 02:00 pm to 04:30 pm, post-break.'}
										{/* {this.props.arabicMode
											? ' من الاثنين إلى الخميس من الساعة 8 صباحًا حتى 5 مساءً ، والجمعة من الساعة 8 صباحًا حتى الساعة 12 ظهرًا ومن 2 إلى 5 مساءً'
											: 'Agent Availability : Mon – Thu 8am till 5pm & Fri 8am - 12pm and 2-5 PM'} */}
									</span>
								</div>
							</Card>
						</Col>
						<Col span={24} className='row-wrapper chat-content-flex'>
							<MessageList
								messages={messageList}
								imageUrl={this.props.agentProfile.imageUrl}
								arabicMode={this.props.arabicMode}
							/>
							<UserInput
								onSubmit={this.onUserInputSubmit.bind(this)}
								onFilesSelected={this.onFilesSelected.bind(this)}
								showEmoji={this.props.showEmoji}
								arabicMode={this.props.arabicMode}
								onAudioInput={this.props.onAudioInput}
								textControl={this.props.textControl}
								loading={this.props.loading}
							/>
						</Col>
						<Col span={0} className='padding-sidebar'>
							<div className='sidebar-wrapper'>
								<div className='sidebar-title'>
									{this.props.arabicMode ? `لوحة المعلومات` : `Information Panel`}
								</div>
								<div className='sidebar-subtitle'>
									{this.props.arabicMode
										? _.upperFirst(_.get(this.props.tabs, [0, 'Keyword']))
										: _.get(this.props.tabs, [0, 'Keyword_Ar'])}
								</div>
								<div className='sidebar-content'>
									<Collapse accordion>
										{_.map(_.slice(this.props.tabs, 0, 4), (each, index) => (
											<Panel
												header={_.get(_.split(each.Question, '\r\n'), [0])}
												key={_.toString(index)}
											>
												<div
													className='links-content'
													dangerouslySetInnerHTML={{ __html: _.get(each, 'Answer', '') }}
												></div>
											</Panel>
										))}
									</Collapse>
								</div>
							</div>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

ChatWindowMobile.propTypes = {
	agentProfile: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFilesSelected: PropTypes.func,
	onUserInputSubmit: PropTypes.func.isRequired,
	showEmoji: PropTypes.bool,
};

export default ChatWindowMobile;
