import React from 'react';
import { Button, Radio } from 'antd';
import chatbotImg from '../../assets/chatbot.jpg';
import dubaiChamberImg from '../../assets/dubaichamber.png';

import i18n from 'i18next';

import { initReactI18next, withTranslation } from 'react-i18next';
import { translationsAr, translationsEn } from '../../Utils/constant';

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: { translation: translationsEn },
			ar: { translation: translationsAr },
		},
		lng: 'en',
		fallbackLng: 'en',
		interpolation: { escapeValue: false },
	});

const WelcomeScreen = (props) => {
	const languageChange = ({ target }) => {
		props.changeLanguage(target.value);
	};
	return (
		<div className='welcome-wrapper-main'>
			<Radio.Group
				defaultValue='en'
				buttonStyle='solid'
				style={{ borderRadius: '14px', textAlign: 'center', direction: 'ltr' }}
				onChange={languageChange}
			>
				<Radio.Button value='en' style={{ width: '100px' }}>
					English
				</Radio.Button>
				<Radio.Button value='ar' style={{ width: '100px' }}>
					عربي
				</Radio.Button>
			</Radio.Group>
			<div justifyContent='center' direction='column' className='welcome-screen-wrapper'>
				{/* <div className={props.arabicMode ? 'welcome-left-card left-border-ar' : 'welcome-left-card'}>
					<img
						style={{ width: '40%' }}
						src={'https://chatbotva-dev.dubaichamber.com/images/chatbot.jpg'}
					></img>
				</div> */}
				<div className={props.arabicMode ? 'welcome-right-card right-border-ar' : 'welcome-right-card'}>
					<img
						style={{ width: '20%' }}
						src={'https://chatbotva-dev.dubaichamber.com/dubaichamber.png'}
						// src={chatbot}
					></img>
					<h2 className='welcome-header'>{props.t('headerTitle')}</h2>
					<img
						style={{ width: '8%' }}
						src={'https://chatbotva-dev.dubaichamber.com/images/chatbot.jpg'}
					></img>
					<Button className='welcome-button' shape='round' onClick={() => props.showForm()}>
						{props.t('get_started')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(WelcomeScreen);
