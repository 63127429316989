import React from 'react';
import { Button, Radio } from 'antd';
import chatbotImg from '../../assets/chatbot.jpg';
import dubaichamberimg from '../../assets/dubaichamber.png';

import i18n from 'i18next';

import { initReactI18next, withTranslation } from 'react-i18next';
import { translationsEn, translationsAr } from '../../Utils/constant';

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: { translation: translationsEn },
			ar: { translation: translationsAr },
		},
		lng: 'en',
		fallbackLng: 'en',
		interpolation: { escapeValue: false },
	});

const WelcomeScreen = (props) => {
	const languageChange = ({ target }) => {
		props.changeLanguage(target.value);
	};
	return (
		<div className='welcome-wrapper-main-mobile'>
			<img style={{ width: '40%' }} src={'https://chatbotva-dev.dubaichamber.com/dubaichamber.png'}></img>
			<Radio.Group
				size='small'
				defaultValue='en'
				buttonStyle='solid'
				style={{ borderRadius: '14px', textAlign: 'center', direction: 'ltr' }}
				onChange={languageChange}
			>
				<Radio.Button value='en' style={{ width: '100px' }}>
					English
				</Radio.Button>
				<Radio.Button value='ar' style={{ width: '100px' }}>
					عربي
				</Radio.Button>
			</Radio.Group>
			<h2 className='welcome-header'>{props.t('headerTitle')}</h2>
			<div className='welcome-logo'>
				<img style={{ width: '30%' }} src={'https://chatbotva-dev.dubaichamber.com/images/chatbot.jpg'}></img>
			</div>
			<Button className='welcome-button' shape='round' onClick={() => props.showForm()}>
				{props.t('get_started')}
			</Button>
		</div>
	);
};

export default withTranslation()(WelcomeScreen);
