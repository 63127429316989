import React, { Component } from 'react';
import Message from './Messages';
import { Scrollbars } from 'react-custom-scrollbars-2';

class MessageList extends Component {
	componentDidUpdate(_prevProps, _prevState) {
		this.refs.scrollbars.scrollToBottom();
	}

	render() {
		return (
			<Scrollbars className='sc-message-list' ref='scrollbars' id='styledc-7'>
				{this.props.messages.map((message, i) => {
					return (
						<Message
							message={message}
							key={i}
							arabicMode={this.props.arabicMode}
							sendMessage={this.props.sendMessage}
						/>
					);
				})}
			</Scrollbars>
		);
	}
}

export default MessageList;
