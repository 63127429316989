import React, { useState } from 'react';
import { Button } from 'antd';
import _ from 'lodash';

const Buttons = (props) => {
	const [isVisible, setVisibility] = useState(true);

	const sendIntent = (details) => {
		// setVisibility(false);
		props.sendMessage(details.intent, details.label);
	};

	return isVisible ? (
		<div className='buttons-wrapper'>
			{_.map(props.data, (each) => (
				<Button shape='round' className='button-options thar-four' onClick={() => sendIntent(each)}>
					{each.label}
				</Button>
			))}
		</div>
	) : null;
};

export default Buttons;
