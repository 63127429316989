export const countries = {
	ext: 'ext.',
	country: 'Phone number country',
	phone: 'Phone',
	AB: 'Arqam Ali',
	AC: 'Ascension Island',
	AD: 'Andorra',
	AE: 'United Arab Emirates',
	AF: 'Afghanistan',
	AG: 'Antigua and Barbuda',
	AI: 'Anguilla',
	AL: 'Albania',
	AM: 'Armenia',
	AO: 'Angola',
	AQ: 'Antarctica',
	AR: 'Argentina',
	AS: 'American Samoa',
	AT: 'Austria',
	AU: 'Australia',
	AW: 'Aruba',
	AX: 'Åland Islands',
	AZ: 'Azerbaijan',
	BA: 'Bosnia and Herzegovina',
	BB: 'Barbados',
	BD: 'Bangladesh',
	BE: 'Belgium',
	BF: 'Burkina Faso',
	BG: 'Bulgaria',
	BH: 'Bahrain',
	BI: 'Burundi',
	BJ: 'Benin',
	BL: 'Saint Barthélemy',
	BM: 'Bermuda',
	BN: 'Brunei Darussalam',
	BO: 'Bolivia',
	BQ: 'Bonaire, Sint Eustatius and Saba',
	BR: 'Brazil',
	BS: 'Bahamas',
	BT: 'Bhutan',
	BV: 'Bouvet Island',
	BW: 'Botswana',
	BY: 'Belarus',
	BZ: 'Belize',
	CA: 'Canada',
	CC: 'Cocos (Keeling) Islands',
	CD: 'Congo, Democratic Republic of the',
	CF: 'Central African Republic',
	CG: 'Congo',
	CH: 'Switzerland',
	CI: "Cote d'Ivoire",
	CK: 'Cook Islands',
	CL: 'Chile',
	CM: 'Cameroon',
	CN: 'China',
	CO: 'Colombia',
	CR: 'Costa Rica',
	CU: 'Cuba',
	CV: 'Cape Verde',
	CW: 'Curaçao',
	CX: 'Christmas Island',
	CY: 'Cyprus',
	CZ: 'Czech Republic',
	DE: 'Germany',
	DJ: 'Djibouti',
	DK: 'Denmark',
	DM: 'Dominica',
	DO: 'Dominican Republic',
	DZ: 'Algeria',
	EC: 'Ecuador',
	EE: 'Estonia',
	EG: 'Egypt',
	EH: 'Western Sahara',
	ER: 'Eritrea',
	ES: 'Spain',
	ET: 'Ethiopia',
	FI: 'Finland',
	FJ: 'Fiji',
	FK: 'Falkland Islands',
	FM: 'Federated States of Micronesia',
	FO: 'Faroe Islands',
	FR: 'France',
	GA: 'Gabon',
	GB: 'United Kingdom',
	GD: 'Grenada',
	GE: 'Georgia',
	GF: 'French Guiana',
	GG: 'Guernsey',
	GH: 'Ghana',
	GI: 'Gibraltar',
	GL: 'Greenland',
	GM: 'Gambia',
	GN: 'Guinea',
	GP: 'Guadeloupe',
	GQ: 'Equatorial Guinea',
	GR: 'Greece',
	GS: 'South Georgia and the South Sandwich Islands',
	GT: 'Guatemala',
	GU: 'Guam',
	GW: 'Guinea-Bissau',
	GY: 'Guyana',
	HK: 'Hong Kong',
	HM: 'Heard Island and McDonald Islands',
	HN: 'Honduras',
	HR: 'Croatia',
	HT: 'Haiti',
	HU: 'Hungary',
	ID: 'Indonesia',
	IE: 'Ireland',
	IL: 'Israel',
	IM: 'Isle of Man',
	IN: 'India',
	IO: 'British Indian Ocean Territory',
	IQ: 'Iraq',
	IR: 'Iran',
	IS: 'Iceland',
	IT: 'Italy',
	JE: 'Jersey',
	JM: 'Jamaica',
	JO: 'Jordan',
	JP: 'Japan',
	KE: 'Kenya',
	KG: 'Kyrgyzstan',
	KH: 'Cambodia',
	KI: 'Kiribati',
	KM: 'Comoros',
	KN: 'Saint Kitts and Nevis',
	KP: 'North Korea',
	KR: 'South Korea',
	KW: 'Kuwait',
	KY: 'Cayman Islands',
	KZ: 'Kazakhstan',
	LA: 'Laos',
	LB: 'Lebanon',
	LC: 'Saint Lucia',
	LI: 'Liechtenstein',
	LK: 'Sri Lanka',
	LR: 'Liberia',
	LS: 'Lesotho',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	LV: 'Latvia',
	LY: 'Libya',
	MA: 'Morocco',
	MC: 'Monaco',
	MD: 'Moldova',
	ME: 'Montenegro',
	MF: 'Saint Martin (French Part)',
	MG: 'Madagascar',
	MH: 'Marshall Islands',
	MK: 'North Macedonia',
	ML: 'Mali',
	MM: 'Burma',
	MN: 'Mongolia',
	MO: 'Macao',
	MP: 'Northern Mariana Islands',
	MQ: 'Martinique',
	MR: 'Mauritania',
	MS: 'Montserrat',
	MT: 'Malta',
	MU: 'Mauritius',
	MV: 'Maldives',
	MW: 'Malawi',
	MX: 'Mexico',
	MY: 'Malaysia',
	MZ: 'Mozambique',
	NA: 'Namibia',
	NC: 'New Caledonia',
	NE: 'Niger',
	NF: 'Norfolk Island',
	NG: 'Nigeria',
	NI: 'Nicaragua',
	NL: 'Netherlands',
	NO: 'Norway',
	NP: 'Nepal',
	NR: 'Nauru',
	NU: 'Niue',
	NZ: 'New Zealand',
	OM: 'Oman',
	OS: 'South Ossetia',
	PA: 'Panama',
	PE: 'Peru',
	PF: 'French Polynesia',
	PG: 'Papua New Guinea',
	PH: 'Philippines',
	PK: 'Pakistan',
	PL: 'Poland',
	PM: 'Saint Pierre and Miquelon',
	PN: 'Pitcairn',
	PR: 'Puerto Rico',
	PS: 'Palestine',
	PT: 'Portugal',
	PW: 'Palau',
	PY: 'Paraguay',
	QA: 'Qatar',
	RE: 'Reunion',
	RO: 'Romania',
	RS: 'Serbia',
	RU: 'Russia',
	RW: 'Rwanda',
	SA: 'Saudi Arabia',
	SB: 'Solomon Islands',
	SC: 'Seychelles',
	SD: 'Sudan',
	SE: 'Sweden',
	SG: 'Singapore',
	SH: 'Saint Helena',
	SI: 'Slovenia',
	SJ: 'Svalbard and Jan Mayen',
	SK: 'Slovakia',
	SL: 'Sierra Leone',
	SM: 'San Marino',
	SN: 'Senegal',
	SO: 'Somalia',
	SR: 'Suriname',
	SS: 'South Sudan',
	ST: 'Sao Tome and Principe',
	SV: 'El Salvador',
	SX: 'Sint Maarten',
	SY: 'Syria',
	SZ: 'Swaziland',
	TA: 'Tristan da Cunha',
	TC: 'Turks and Caicos Islands',
	TD: 'Chad',
	TF: 'French Southern Territories',
	TG: 'Togo',
	TH: 'Thailand',
	TJ: 'Tajikistan',
	TK: 'Tokelau',
	TL: 'Timor-Leste',
	TM: 'Turkmenistan',
	TN: 'Tunisia',
	TO: 'Tonga',
	TR: 'Turkey',
	TT: 'Trinidad and Tobago',
	TV: 'Tuvalu',
	TW: 'Taiwan',
	TZ: 'Tanzania',
	UA: 'Ukraine',
	UG: 'Uganda',
	UM: 'United States Minor Outlying Islands',
	US: 'United States',
	UY: 'Uruguay',
	UZ: 'Uzbekistan',
	VA: 'Holy See (Vatican City State)',
	VC: 'Saint Vincent and the Grenadines',
	VE: 'Venezuela',
	VG: 'Virgin Islands, British',
	VI: 'Virgin Islands, U.S.',
	VN: 'Vietnam',
	VU: 'Vanuatu',
	WF: 'Wallis and Futuna',
	WS: 'Samoa',
	XK: 'Kosovo',
	YE: 'Yemen',
	YT: 'Mayotte',
	ZA: 'South Africa',
	ZM: 'Zambia',
	ZW: 'Zimbabwe',
	ZZ: 'International',
};


export const services = {

	true_copy_listing: [

		'Amend Article of Association',

		"License",

		"True Copy",

		"Others",

		"Membership Certificate",

	],
	verify_signature_listing: [

		"Agency Contract",

		"Visit Visa Application Form",

		"Agreement Supplementry",

		"Analysis Certificate",

		"Auditors Report",

		"Auth Letter to Saudi Consulate",

		"Authorization",

		"Authorization Letter to Saudi",

		"Board of Directors Resolution",

		"Boat Sale Agreement",

		"Boat Sale Invoice",

		"Budget Statement",

		"COO Supplementry",

		"Certificate",

		"Certificate of Insur Suplement",

		"Certificate of Insurance",

		"Certificate of Insurance Suppl",

		"Certificate of Origin Suppleme",

		"Certificate of Surveillance",

		"Chemical Analysis Certificate",

		"Clarification",

		"Commercial Inv / Attach to COO",

		"Commercial Invoice / Attached t",

		"Contract",

		"Contractors Registration Form",

		"Customs Packing List",

		"Distribution Contract",

		"DulyTranslated Document",

		"Employment Application Form",

		"Entry / Exit Certificate",

		"Experience Certificate",
		"Export Health Certificate",

		"Foreign Certificate of Origin",
		"Foreign Invoice",

		"Foreign Invoice + Foreign COO",

		"Foreign Invoice + Foreign Cert",

		"Freight Bill",

		"Health Certificate",

		"Invitation",

		"Invoice",

		"Job Offer",
		"Memorandum of Understanding",

		"Minutes",

		"No Objection",

		"Packing List",

		"Power Attorney",

		"Price List",

		'Proforma Invoice',

		"Returned Goods Certificate",

		"Salary Certificate",

		"Sale(Discount) Invoice",

		"Service Invoice",

		"To Whom it May Concern",

		"Transfer of Video Rights",

		'Agreement',
	],
	authenticate_certificate_listing: [

		"Certificate of Returned Mercha",

		"Entrance Bill",

		"Exit Bills",

		"To Whom It may Concern",
		"Foreign invoice",

		"Packing List",

		"Foreign certificate of origin",
	]

}