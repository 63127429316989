import React, { useState } from 'react';
import { Button, Form, Card, Input } from 'antd';
import i18n from 'i18next';

import { initReactI18next, withTranslation } from 'react-i18next';
import axios from 'axios';
import _ from 'lodash';
import { translationsAr, translationsEn } from '../Utils/constant';

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: { translation: translationsEn },
			ar: { translation: translationsAr },
		},
		lng: 'en',
		fallbackLng: 'en',
		interpolation: { escapeValue: false },
	});

const NoAgentForm = (props) => {
	const [form] = Form.useForm();
	const [isDisabled, setDisabled] = useState(false);
	const { arabicMode } = props;

	const onFinish = (values) => {
		props.setChat();
		// console.log(props);
		setDisabled(true);
		form.validateFields().then((values) => {
			axios.post('https://chatbotva.dubaichamber.com/api/query', {
				name: _.get(values.name, ''),
				mobile: null,
				email: values.email,
				subject: values.subject,
				query: values.query,
			});
		});
	};

	return (
		<div
			style={{
				margin: 'auto',
				borderRadius: '16px',
				boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
				width: '90%',
			}}
		>
			<Card style={{ borderRadius: '15px', background: '#f1f1fb' }} justifyContent='center' direction='column'>
				<Form form={form} onFinish={onFinish}>
					<Form.Item name='email' rules={[{ required: true, message: props.t('email_error') }]}>
						<Input
							disabled={isDisabled}
							placeholder={arabicMode ? 'موضوعات' : 'Email'}
							style={{ borderRadius: '10px' }}
						/>
					</Form.Item>
					<Form.Item name='subject' rules={[{ required: true, message: props.t('subject_error') }]}>
						<Input
							disabled={isDisabled}
							placeholder={arabicMode ? 'موضوعات' : 'Subject'}
							style={{ borderRadius: '10px' }}
						/>
					</Form.Item>
					<Form.Item name='query' rules={[{ required: true, message: props.t('query_error') }]}>
						<Input.TextArea
							disabled={isDisabled}
							placeholder={arabicMode ? 'من فضلك اكتب لنا استفسارك' : 'Please write to us your query'}
							style={{ borderRadius: '10px', minHeight: '100px' }}
						/>
					</Form.Item>
					<Form.Item>
						<Button
							shape='round'
							htmlType='submit'
							disabled={isDisabled}
							style={{
								background: '#0a3b5c',
								float: 'right',
								border: '0px',
								color: '#ffff',
							}}
						>
							{arabicMode ? 'إرسال' : 'Submit'}
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</div>
	);
};

export default withTranslation()(NoAgentForm);
