import React, { Component } from 'react';
import TextMessage from './TextMessage';
import EmojiMessage from './EmojiMessage';
import FileMessage from './FileMessage';

class Message extends Component {
	_renderMessageOfType(type) {
		switch (type) {
			case 'text':
				return <TextMessage {...this.props.message} arabicMode={this.props.arabicMode} sendMessage={this.props.sendMessage} />;
			case 'emoji':
				return <EmojiMessage {...this.props.message} />;
			case 'file':
				return <FileMessage {...this.props.message} />;
			case 'other_message':
				return <div>helllp</div>;
			default:
				console.error(`Attempting to load message with unsupported file type '${type}'`);
		}
	}

	render() {
		let contentClassList = ['sc-message--content', this.props.message.author === 'me' ? 'sent' : 'received'];
		return this.props.message.type === 'other_message' ? (
			<div className='sc-message' style={{ padding: '10px 20px' }}>
				{this.props.message.data.text}
			</div>
		) : (
			<div
				className='sc-message'
				style={{
					alignSelf: this.props.message.author === 'me' ? 'end' : 'start',
				}}
			>
				{this._renderMessageOfType(this.props.message.type)}
			</div>
		);
	}
}

export default Message;
