import React, { Component } from 'react';
import SendIcon from './icons/SendIcon';
import FileIcon from './icons/FileIcon';
import PopupWindow from './popups/PopupWindow';
import EmojiPicker from './emoji-picker/EmojiPicker';
import axios from 'axios';
import { Button, Upload } from 'antd';
import { AudioFilled, SendOutlined, PaperClipOutlined } from '@ant-design/icons';
import { ResultReason, SpeechConfig, AudioConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import _ from 'lodash';
import { Form as MobileForm, Input as MobileInput, Space } from 'antd-mobile';
import { isMobile } from 'react-device-detect';
import loadingImg from '../assets/loading.gif';
import { liveagent } from '../../config/production.json';
// import { liveagent } from '../../config/development.json';

class UserInput extends Component {
	constructor() {
		super();
		this.state = {
			inputActive: false,
			inputHasText: false,
			emojiPickerIsOpen: false,
			emojiFilter: '',
			isAudio: false,
		};
	}
	formRef = React.createRef();

	componentDidMount() {
		this.emojiPickerButton = document.querySelector('#sc-emoji-picker-button');
	}

	handleKeyDown(event) {
		if (event.keyCode === 13 && !event.shiftKey) {
			return this._submitText(event);
		}
	}

	handleKeyUp(event) {
		const inputHasText = event.target.innerHTML.length !== 0 && event.target.innerText !== '\n';
		this.setState({ inputHasText });
	}

	_showFilePicker() {
		this._fileUploadButton.click();
	}

	toggleEmojiPicker = (e) => {
		e.preventDefault();
		if (!this.state.emojiPickerIsOpen) {
			this.setState({ emojiPickerIsOpen: true });
		}
	};

	closeEmojiPicker = (e) => {
		if (this.emojiPickerButton.contains(e.target)) {
			e.stopPropagation();
			e.preventDefault();
		}
		this.setState({ emojiPickerIsOpen: false });
	};

	_submitText(event) {
		event.preventDefault();
		const text = this.userInput.textContent;
		if (text && text.length > 0) {
			this.props.onSubmit({
				author: 'me',
				type: 'text',
				data: { text },
			});
			this.userInput.innerHTML = '';
		}
	}

	submit() {
		const text = this.userInput.textContent;

		if (text && text.length > 0) {
			this.props.onSubmit({
				author: 'me',
				type: 'text',
				data: { text },
			});
			this.userInput.innerHTML = '';
		}
	}

	_onFilesSelected(event) {
		if (event.target.files && event.target.files.length > 0) {
			this.props.onFilesSelected(event.target.files);
		}
	}

	_handleEmojiPicked = (emoji) => {
		this.setState({ emojiPickerIsOpen: false });
		if (this.state.inputHasText) {
			this.userInput.innerHTML += emoji;
		} else {
			this.props.onSubmit({
				author: 'me',
				type: 'emoji',
				data: { emoji },
			});
		}
	};

	handleEmojiFilterChange = (event) => {
		const emojiFilter = event.target.value;
		this.setState({ emojiFilter });
	};

	_renderEmojiPopup = () => (
		<PopupWindow
			isOpen={this.state.emojiPickerIsOpen}
			onClickedOutside={this.closeEmojiPicker}
			onInputChange={this.handleEmojiFilterChange}
		>
			<EmojiPicker onEmojiPicked={this._handleEmojiPicked} filter={this.state.emojiFilter} />
		</PopupWindow>
	);

	_renderSendOrFileIcon() {
		if (this.state.inputHasText) {
			return (
				<div className='sc-user-input--button'>
					<SendIcon onClick={this._submitText.bind(this)} />
				</div>
			);
		}
		return (
			<div className='sc-user-input--button'>
				<FileIcon onClick={this._showFilePicker.bind(this)} />
				<input
					type='file'
					name='files[]'
					multiple
					ref={(e) => {
						this._fileUploadButton = e;
					}}
					onChange={this._onFilesSelected.bind(this)}
				/>
			</div>
		);
	}

	async sttFromMic() {
		const headers = {
			headers: {
				'Ocp-Apim-Subscription-Key': 'f9af2415072a4ab2b579e1358c6fbf57',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		this.setState({ isAudio: true });
		const res = await axios.post('https://eastus2.api.cognitive.microsoft.com/sts/v1.0/issuetoken', null, headers);

		const speechConfig = SpeechConfig.fromAuthorizationToken(res.data, 'eastus2');
		speechConfig.speechRecognitionLanguage = this.props.arabicMode ? 'ar-AE' : 'en-US';

		const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
		const recognizer = new SpeechRecognizer(speechConfig, audioConfig);

		recognizer.recognizeOnceAsync((result) => {
			let displayText;
			if (result.reason === ResultReason.RecognizedSpeech) {
				displayText = result.text;
			} else {
				displayText = 'Speech could not be recognized. Ensure your microphone is working properly.';
			}
			this.setState({ isAudio: false });
			this.props.onAudioInput({ data: { text: displayText } });
		});
	}

	audioActivate = async () => {};

	sendMessage = () => {
		const message = _.get(this.formRef.current.getFieldsValue(), 'message', '');
		if (!_.isEmpty(message)) {
			this.props.onSubmit({
				author: 'me',
				type: 'text',
				data: { text: message },
			});
		}
		this.formRef.current.resetFields();
	};

	attachFile = async (file) => {
		const loginFormData = new FormData();
		loginFormData.append('msg', file.name);
		loginFormData.append('description', '');
		loginFormData.append('file', file);
		// console.log('==== user Input file ====', fileUploadID);
		// const res1 = await axios.post('https://liveagentva-dev.dubaichamber.com/api/v1/login', {
		// 	user: fileUploadID,
		// 	password: fileuploadKey,
		// });
		// const res1 = await axios.get(liveagent);

		const res1 = await axios.post(liveagent, { userId: 'fileUploadId', serverType: 'prod' });

		const rid = sessionStorage.getItem('rid');

		const headers = {
			'X-Auth-Token': _.get(res1, 'data.authToken'),
			'X-User-Id': _.get(res1, 'data.userId'),
			'Content-Type': 'multipart/form-data',
		};

		const res = await axios.post(`https://liveagentva.dubaichamber.com/api/v1/rooms.upload/${rid}`, loginFormData, {
			headers,
		});

		this.props.onFileAttach(file);

		return true;
	};

	renderNonLodingComponent = () => {
		const { inputActive, isAudio } = this.state;
		return !isMobile ? (
			<form className={`sc-user-input ${inputActive ? 'active' : ''}`}>
				<Button
					shape='circle'
					disabled={this.state.isAudio || this.state.inputHasText || !this.props.textControl}
					className='input-button '
				>
					<AudioFilled
						style={{ fontSize: '20px', color: isAudio ? '#dc4405' : '' }}
						onClick={this.audioActivate}
					></AudioFilled>
				</Button>
				<Button shape='circle' className='input-button'>
					<Upload
						shape='circle'
						// disabled={this.state.isAudio || this.state.inputHasText || !this.props.textControl}
						// className='input-button'
						beforeUpload={this.attachFile}
						accept='application/pdf, application/docx'
					>
						<PaperClipOutlined
							style={{ fontSize: '20px', color: isAudio ? '#dc4405' : '' }}
							// onClick={this.attachFile}
						></PaperClipOutlined>
					</Upload>
				</Button>
				{this.state.isAudio || this.props.loading ? (
					<img
						src={'https://chatbotva-dev.dubaichamber.com/images/audio_text.gif'}
						style={{
							width: '79%',
							height: '100%',
							borderBottomLeftRadius: '15px',
							borderBottomRightRadius: '15px',
						}}
					></img>
				) : (
					<div
						role='button'
						tabIndex='1'
						onFocus={() => {
							this.setState({ inputActive: true });
						}}
						onBlur={() => {
							this.setState({ inputActive: false });
						}}
						ref={(e) => {
							this.userInput = e;
						}}
						onKeyDown={this.handleKeyDown.bind(this)}
						onKeyUp={this.handleKeyUp.bind(this)}
						type='text'
						id='message-input'
						lang={this.props.arabicMode ? 'ar' : 'en'}
						contentEditable={this.props.textControl}
						placeholder={this.props.arabicMode ? 'اختر شيئاً...' : 'Write a reply...'}
						className='sc-user-input--text'
					></div>
				)}
				<div className='sc-user-input--button'>
					<Button
						shape='circle'
						onClick={() => this.submit()}
						// disabled={!this.state.inputHasText || isAudio}
						className='input-button send-button'
					>
						<SendOutlined
							style={{
								fontSize: '20px',
								color: isAudio ? '#dc4405' : '',
								marginLeft: this.props.arabicMode ? '0' : '5px',
								marginRight: this.props.arabicMode ? '5px' : '0',
							}}
							rotate={this.props.arabicMode ? 180 : 0}
						/>
					</Button>
				</div>
			</form>
		) : (
			<MobileForm onFinish={this.sendMessage} ref={this.formRef} className='mobile-input-form'>
				<Space wrap>
					<MobileForm.Item name='message'>
						{this.state.isAudio ? (
							<img
								src={'https://chatbotva-dev.dubaichamber.com/images/audio_text.gif'}
								style={{
									width: '100%',
									height: '15px',
									borderBottomLeftRadius: '15px',
									borderBottomRightRadius: '15px',
								}}
							></img>
						) : (
							<MobileInput
								disabled={!this.props.textControl}
								placeholder={this.props.arabicMode ? 'اختر شيئاً...' : 'Write a reply...'}
								className='mobile-input'
							/>
						)}
					</MobileForm.Item>
					<MobileForm.Item>
						<Button shape='circle' className='input-button'>
							<Upload
								shape='circle'
								// disabled={this.state.isAudio || this.state.inputHasText || !this.props.textControl}
								// className='input-button'
								beforeUpload={this.attachFile}
								accept='application/pdf, application/docx'
							>
								<PaperClipOutlined
									style={{ fontSize: '20px', color: isAudio ? '#dc4405' : '' }}
									// onClick={this.attachFile}
								></PaperClipOutlined>
							</Upload>
						</Button>
					</MobileForm.Item>
					<MobileForm.Item>
						<SendOutlined
							type='primary'
							htmlType='submit'
							disabled={isAudio}
							rotate={this.props.arabicMode ? 180 : 0}
							style={{ borderRadius: '5px', width: '100%' }}
							onClick={this.sendMessage}
						></SendOutlined>
					</MobileForm.Item>
				</Space>
			</MobileForm>
		);
	};

	render() {
		return this.props.loading ? (
			<div
				className='recevie-message-wrapper'
				style={{
					padding: '0 0.9em',
					marginBottom: '7em',
				}}
			>
				<div className='recevie-message-header'>
					<div className='avatar-wrapper'>
						<img
							src={'https://chatbotva-dev.dubaichamber.com/images/chatbot.jpg'}
							alt='Avatar'
							className='avatar'
						></img>
					</div>
					<div className='receive-message-text-wrapper'>
						<div>
							{/* <div className='receive-message-title'>{props.user}</div> */}
							<div className='receive-message-title'></div>
						</div>
						<div
							className={
								this.props.arabicMode
									? 'receive-message-text right-top'
									: 'receive-message-text left-top'
							}
						>
							<div style={{ whiteSpace: 'pre-wrap' }}>
								<img
									style={{ width: '25%' }}
									src={'https://chatbotva-dev.dubaichamber.com/images/loading.gif'}
								></img>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : (
			this.renderNonLodingComponent()
		);
	}
}

export default UserInput;
