import React, { useState } from 'react';
import './style.css';
import { Rate } from 'antd';
import { FrownFilled, FrownOutlined, MehFilled, MehOutlined, SmileFilled, SmileOutlined } from '@ant-design/icons';

const FeedbackSmily = ({ feedbackSelectHandler, defaultValue }) => {
	const [selected, setSelected] = useState('');

	const customIcons = {
		5:
			selected === 5 ? (
				<SmileFilled style={{ fontSize: 40, padding: '6px', color: 'green' }} />
			) : (
				<SmileOutlined style={{ fontSize: 40, padding: '6px', color: 'green' }} />
			),
		4:
			selected === 4 ? (
				<SmileFilled style={{ fontSize: 40, padding: '6px', color: '#77d777' }} />
			) : (
				<SmileOutlined style={{ fontSize: 40, padding: '6px', color: '#77d777' }} />
			),
		3:
			selected === 3 ? (
				<MehFilled style={{ fontSize: 40, padding: '6px', color: '#ffcc00' }} />
			) : (
				<MehOutlined style={{ fontSize: 40, padding: '6px', color: '#ffcc00' }} />
			),
		2:
			selected === 2 ? (
				<FrownFilled style={{ fontSize: 40, padding: '6px', color: 'orange' }} />
			) : (
				<FrownOutlined style={{ fontSize: 40, padding: '6px', color: 'orange' }} />
			),
		1:
			selected === 1 ? (
				<FrownFilled style={{ fontSize: 40, padding: '6px', color: 'red' }} />
			) : (
				<FrownOutlined style={{ fontSize: 40, padding: '6px', color: 'red' }} />
			),
	};

	return (
		<div className='dubaiChamberfeedbackSmilyContainer'>
			<Rate
				defaultValue={Number(defaultValue)}
				onChange={(e) => {
					feedbackSelectHandler(e);
					setSelected(e);
				}}
				character={({ index }) => customIcons[index + 1]}
			/>
		</div>
	);
};

export default FeedbackSmily;
